import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import '../styles/temp.css'
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import { debug } from '../utils/commonUtils';

const queryClient = new QueryClient();

export default function AuthorizeUser(props: {admin: boolean; employeeId: number; username: string}) {
  debug(`AuthorizeUser(): props is ${JSON.stringify(props)}`);

  const stage = 'beta';

  const getEnvironment = () => {
    switch (stage.toLowerCase()) {
      case 'prod':
        return '';
      default:
        return `Environment: ${stage}`;
    }
  };

  let componentToRender;

  componentToRender = (
    <QueryClientProvider client={queryClient}>
      {window.location.href.includes('#')
      &&
      <HashRouter>
        <Routes>
          <Route
            path='/management'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                username={props.username}
              />
            }
          />
          <Route
            path='/management/manageaccessrequests'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Access Requests'
                username={props.username}
              />
            }
          />
          <Route
            path='*'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.SELF_SERVICE}
                username={props.username}
              />
            }
          />
        </Routes>
      </HashRouter>
      }
      {!window.location.href.includes('#')
      &&
      <BrowserRouter>
        <Routes>
          <Route
            path='/management/manageaccessrequests'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Access Requests'
                username={props.username}
              />
            }
          />
          <Route
            path='/management/manageaccessrequests/:visitorAccessLevelApprovalId/:action'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Access Requests'
                username={props.username}
              />
            }
          />
          <Route
            path='/management/manageaccessrequests/:visitorAccessLevelApprovalId/:action/:denyReasonId'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Access Requests'
                username={props.username}
              />
            }
          />
          <Route
            path='/management/manageassets'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Assets'
                username={props.username}
              />
            }
          />
          <Route
            path='/management/managevisitors'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                page='Manage Visitors'
                username={props.username}
              />
            }
          />
          <Route
            path='/management'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.MANAGEMENT}
                username={props.username}
              />
            }
          />
          <Route
            path='*'
            element={
              <App
                admin={props.admin}
                darkMode={false}
                employeeId={props.employeeId}
                mode={Modes.SELF_SERVICE}
                username={props.username}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      }
    </QueryClientProvider>
  );

  return componentToRender;
}
