import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  FormField,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useBundle } from "@amzn/react-arb-tools";
import { ILanguage, Languages, ManagementPages, Modes, NavigationTypes, SelfServicePages } from '../../constants/Constants';
import * as APIt from '../../API';
import { createUserPreferences, queryUserPreferences, updateUserPreferences } from '../SelfService/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { debug } from 'src/utils/commonUtils';

interface IUserPreferencesProps {
  admin: boolean;
  closeUserPreferencesCallback(): void;
  darkMode: boolean;
  disableAutoApproval: boolean;
  disableNewVendorDayPassRequests: boolean | undefined;
  enableDebugLogging: boolean;
  language: ILanguage | undefined;
  mode: Modes;
  setAdminCallback: Function;
  setDisableAutoApprovalCallback: Function;
  setEnableDebugLoggingCallback: Function;
  username: string;
  userPrefs: APIt.UserPrefs | undefined | null,
  userPrefsIsLoading: boolean,
}

export const UserPreferences = (props: IUserPreferencesProps) => {
  debug(`UserPreferences() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.common.UserPreferences');

  const getDarkMode = (): boolean => {
    let darkMode: boolean = false;

    if (props.mode == Modes.MANAGEMENT
    && props.userPrefs?.management
    && JSON.parse(props.userPrefs?.management).darkMode)
      darkMode = JSON.parse(props.userPrefs?.management).darkMode;
    if (props.mode == Modes.SELF_SERVICE
    && props.userPrefs?.selfService
    && JSON.parse(props.userPrefs?.selfService).darkMode)
      darkMode = JSON.parse(props.userPrefs?.selfService).darkMode;

    return darkMode;
  };

  const getInitialPage = (): string | undefined => {
    let initialPage: string | undefined = 'Home';

    if (props.mode == Modes.MANAGEMENT
    && props.userPrefs?.management
    && JSON.parse(props.userPrefs.management).initialPage)
      initialPage = JSON.parse(props.userPrefs?.management).initialPage;

    if (props.mode == Modes.SELF_SERVICE
    && props.userPrefs?.selfService
    && JSON.parse(props.userPrefs.selfService).initialPage)
        initialPage = JSON.parse(props.userPrefs?.selfService).initialPage;

    return initialPage;
  };

  const getLanguage = (): { value: string, label: string } | null => {
    if (!props.userPrefs?.global) return {label: 'English', value: 'en-US'};
    try {
      const globalPrefs = JSON.parse(props.userPrefs.global);
      const language = Languages.find(l => l.id == globalPrefs.language.value)
      if (!language?.id || !language?.text) return null;
      return { value: language?.id, label: language?.text };
    } catch(error) {
      return null;
    }
  };

  const getNavigationType = (): NavigationTypes => {
    let navigationType: NavigationTypes = NavigationTypes.SIDE_MENU;

    if (props.mode == Modes.MANAGEMENT && props.userPrefs?.management && JSON.parse(props.userPrefs.management).navigationType) {
      navigationType = JSON.parse(props.userPrefs?.management).navigationType as NavigationTypes;
    }
    if (props.mode == Modes.SELF_SERVICE && props.userPrefs?.selfService && JSON.parse(props.userPrefs.selfService).navigationType)
      navigationType = JSON.parse(props.userPrefs?.selfService).navigationType as NavigationTypes;

    return navigationType;
  };

  const [enableDebugLogging, setEnableDebugLogging] = useState<boolean>(props.enableDebugLogging);
  const [disableAdmin, setDisableAdmin] = useState<boolean>(false);
  const [disableAutoApproval, setDisableAutoApproval] = useState<boolean>(props.disableAutoApproval);
  const [selectedNavigationType, setSelectedNavigationType] = useState<NavigationTypes>(getNavigationType());
  const [selectedInitialPage, setSelectedInitialPage] = useState<string | undefined>(getInitialPage());
  const [selectedDarkMode, setSelectedDarkMode] = useState<boolean>(getDarkMode());
  const [selectedLanguage, setSelectedLanguage] = useState< { value: string, label: string } | null >(getLanguage());

  const queryClient = useQueryClient();

  const initialPageOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedInitialPage(detail.selectedOption.value);
  };

  const darkModeOnChangeHandler = (detail: ToggleProps.ChangeDetail) => {
    setSelectedDarkMode(detail.checked);
  };

  const saveUserPrefs = async () => {
    const userPrefs: APIt.UserPrefs | null = await queryUserPreferences(props.username);

    let
      managementPrefs = userPrefs?.management ? JSON.parse(userPrefs.management) : {},
      selfServicePrefs = userPrefs?.selfService ? JSON.parse(userPrefs.selfService): {};

    if (props.mode == Modes.MANAGEMENT)
      managementPrefs =
        {
          ...JSON.parse(userPrefs?.management || '{}'),
          darkMode: selectedDarkMode,
          initialPage: selectedInitialPage,
          navigationType: selectedNavigationType,
        };

    if (props.mode == Modes.SELF_SERVICE)
      selfServicePrefs =
        {
          ...JSON.parse(userPrefs?.selfService || '{}'),
          darkMode: selectedDarkMode,
          initialPage: selectedInitialPage,
          navigationType: selectedNavigationType,
        };

      const newUserPrefs: APIt.UserPrefs = {
        __typename: 'UserPrefs',
        global: JSON.stringify({
          ...JSON.parse(userPrefs?.global || '{}'),
          language: selectedLanguage
        }),
        management: JSON.stringify(managementPrefs),
        selfService: JSON.stringify(selfServicePrefs),
        username: props.username,
      };

      if (userPrefs) await updateUserPreferences(newUserPrefs);
      if (!userPrefs) await createUserPreferences(newUserPrefs);

    queryClient.invalidateQueries({ queryKey: ['userPrefs'] })
    props.closeUserPreferencesCallback();
  };

  const saveUserPrefsMutation = useMutation({
    mutationFn: async () => {
      await saveUserPrefs();
      if (disableAdmin) props.setAdminCallback(false);
      props.setDisableAutoApprovalCallback(disableAutoApproval);
    },
  });

  if (isBundleLoading || props.userPrefsIsLoading) return <Spinner/>;

  return (
    <Modal
      visible={true}
      header={bundle.getMessage('user-preferences')}
      onDismiss={() => props.closeUserPreferencesCallback()}
      closeAriaLabel='close user preferences'
      size='small'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='primary'
              onClick={() => saveUserPrefsMutation.mutate()}
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='m' direction='vertical'>
        <FormField label={bundle.getMessage('navigation-type')}>
          <Select
            placeholder={bundle.getMessage('select-navigation-type')}
            onChange={({ detail }) => setSelectedNavigationType(detail.selectedOption.value! as NavigationTypes)}
            options={
              [
                {
                  label: bundle.getMessage('side-menu'),
                  value: 'side-menu'
                },
                {
                  label: bundle.getMessage('tabs'),
                  value: 'tabs'
                },
              ]
            }
            ariaLabel={bundle.getMessage('select-navigation-type')}
            selectedAriaLabel='Selected Navigation Type'
            ariaDescribedby={selectedNavigationType}
            renderHighlightedAriaLive={option => `Select Page ${option.label}`}
            ariaRequired
            selectedOption={ { label: bundle.getMessage(selectedNavigationType || 'side-menu'), value: selectedNavigationType || 'side-menu'} }
          />
        </FormField>
        <FormField label={bundle.getMessage('initial-page')}>
          <Select
            placeholder={bundle.getMessage('select-initial-page')}
            onChange={({ detail }) => initialPageOnChangeHandler(detail)}
            options={
              props.mode == Modes.MANAGEMENT
                ? ManagementPages.filter(mp => mp.name != 'Administration')
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
                : SelfServicePages.filter(ssp => props.disableNewVendorDayPassRequests ? ssp.code !== 'vendor-day-pass-request' : true)
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
            }
            ariaLabel={bundle.getMessage('select-initial-page')}
            selectedAriaLabel='Selected Initial Page'
            ariaDescribedby={selectedInitialPage}
            renderHighlightedAriaLive={option => `Select Page ${option.label}`}
            ariaRequired
            selectedOption={{ label: selectedInitialPage, value: selectedInitialPage }}
          />
        </FormField>
        <FormField label={bundle.getMessage('dark-mode')}>
          <Toggle
            onChange={({ detail }) => darkModeOnChangeHandler(detail)}
            ariaLabel={bundle.getMessage('dark-mode')}
            checked={selectedDarkMode}
          />
        </FormField>
        <FormField label={bundle.getMessage('language')}>
          <Select
            onChange={({ detail }) => setSelectedLanguage({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
            options={Languages.map(l => { return { label: l.text, value: l.id }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
            selectedOption={selectedLanguage}
          />
        </FormField>
        {props.admin
        &&
        <div>
        <hr/>
        <Alert type='warning'>
          The following settings are for testing/support, they are not saved.
        </Alert>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setDisableAdmin(detail.checked);
            }}
            ariaLabel='disable admin'
            checked={disableAdmin}
          >
            Disable Admin
          </Toggle>
        </FormField>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setDisableAutoApproval(detail.checked);
            }}
            ariaLabel='disable auto approval'
            checked={disableAutoApproval}
          >
            Disable Auto Approval
          </Toggle>
        </FormField>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setEnableDebugLogging(detail.checked);
              props.setEnableDebugLoggingCallback(detail.checked);
            }}
            ariaLabel='enable debug logging'
            checked={enableDebugLogging}
          >
            Enable Debug Logging
          </Toggle>
        </FormField>
        </div>
        }
      </SpaceBetween>
    </Modal>
  );
}
