import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';
const arbManifest = require('../build/i18n/arbManifest');
import { DEFAULT_LOCALE, DEFAULT_LC_BUILDER } from "./constants/Constants";
import { MbmProvider } from "@amzn/react-arb-tools";

import AuthenticateUser from './components/AuthenticateUser';
import AppInitWrapper from './components/AppInitWrapper';

import './styles/index.scss';
import { Amplify, Auth } from 'aws-amplify';
import { Spinner } from '@amzn/awsui-components-react';
import { debug } from './utils/commonUtils';

// @ts-ignore because this is a replacement var
if ('beta'.toLowerCase() !== 'prod') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const logger = new Amplify.Logger('test');
logger.log(`index.tsx`);
debug('Stage is ' + 'beta');
debug('AWS_REGION is ' + 'us-east-1');
debug('IDENTITY_POOL_DOMAIN is ' + 'gso-welcome-beta.auth.us-east-1.amazoncognito.com');
debug('IDENTITY_POOL_ID is ' + 'us-east-1:08c3445c-b578-477f-9a14-083ec7b53ed6');
debug('USER_POOL_ID is ' + 'us-east-1_SNOHe4yJE');

const callbackUrl = window.location.origin;
const config = {
  oauth: {
    domain: 'gso-welcome-beta.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:08c3445c-b578-477f-9a14-083ec7b53ed6',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_SNOHe4yJE',
  aws_user_pools_web_client_id: '3vd7a2s938opotq69jkdq6n7f3',
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.beta.welcome.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint: 'https://api.beta.welcome.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
  urlPrefix: '/i18n',
};

ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<Spinner size='large' />}>
        <MbmProvider {...mbmOptions}>
          <AuthenticateUser />
        </MbmProvider>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
