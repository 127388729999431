/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AccessControlRequestStatusInput = {
  methodName: string,
  params: AccessControlRequestStatusParamsInput,
};

export type AccessControlRequestStatusParamsInput = {
  RequestUUID?: string | null,
};

export type AccessControlRequestStatusResponse = {
  __typename: "AccessControlRequestStatusResponse",
  error?: string | null,
  message?: string | null,
  requestUUID?: string | null,
  statusCode: number,
};

export type BadgeAccessLevelRequestInput = {
  methodName: string,
  params: BadgeAccessLevelRequestParamsInput,
};

export type BadgeAccessLevelRequestParamsInput = {
  PersonID?: string | null,
  AccessLevelID: number,
  ActivateTimeEpoch?: string | null,
  DeactivateTimeEpoch?: string | null,
  Priority?: string | null,
  WelcomeID?: string | null,
};

export type BadgeAccessLevelRequestResponse = {
  __typename: "BadgeAccessLevelRequestResponse",
  statusCode: number,
  error?: string | null,
  message?: string | null,
  requestUUID?: string | null,
};

export type BadgeAssignmentInput = {
  methodName: string,
  params: BadgeAssignmentRequestParamsInput,
};

export type BadgeAssignmentRequestParamsInput = {
  EmployeeID?: string | null,
  PersonID?: string | null,
  BadgeNumber: string,
  BadgeStatus?: number | null,
  BadgeType?: number | null,
  IssueTimeEpoch?: string | null,
  ExpireTimeEpoch?: string | null,
  Priority?: string | null,
  WelcomeID?: string | null,
};

export type BadgeAssignmentRequestResponse = {
  __typename: "BadgeAssignmentRequestResponse",
  statusCode: number,
  error?: string | null,
  message?: string | null,
  requestUUID?: string | null,
};

export type CardholderRequestInput = {
  methodName: string,
  params: CardholderRequestParamsInput,
};

export type CardholderRequestParamsInput = {
  Alias?: string | null,
  Barcode?: number | null,
  EmployeeStatus: string,
  EmployeeType: number,
  FirstName: string,
  LastName: string,
  OnGuardCardholderID?: number | null,
  Priority?: string | null,
  WelcomeID: string,
};

export type CardholderRequestResponse = {
  __typename: "CardholderRequestResponse",
  statusCode: number,
  error?: string | null,
  message?: string | null,
  requestUUID?: string | null,
};

export type CreateApproverGroupInput = {
  id: string,
  access_level: string,
  approver_group: string,
  created_by: string,
};

export type ApproverGroup = {
  __typename: "ApproverGroup",
  id: string,
  access_level: string,
  approver_group: string,
  created: string,
  updated: string,
  created_by: string,
  updated_by: string,
};

export type CreateDelegationInput = {
  created_by: string,
  delegator_id: string,
  delegatee_id: string,
  delegation_type_id: string,
  end_date?: string | null,
  id: string,
  justification: string,
  permanent_flag: boolean,
  start_date?: string | null,
  updated_by: string,
};

export type Delegation = {
  __typename: "Delegation",
  created: string,
  created_by: string,
  delegator_id: string,
  delegator_username?: string | null,
  delegatee_id: string,
  delegatee_username?: string | null,
  delegation_type_id: string,
  delegation_type?: string | null,
  end_date?: string | null,
  justification: string,
  id: string,
  permanent_flag: boolean,
  start_date?: string | null,
  updated: string,
  updated_by: string,
};

export type CreateLookupTypeInput = {
  name: string,
  description: string,
  enabled: boolean,
  created_by: string,
};

export type LookupType = {
  __typename: "LookupType",
  id: string,
  name: string,
  description: string,
  enabled: boolean,
  created: string,
  updated: string,
  created_by: string,
  updated_by: string,
};

export type CreateLookupTypeValueInput = {
  lookup_type_id: string,
  value: string,
  description: string,
  enabled: boolean,
  start_date?: string | null,
  end_date?: string | null,
  created_by: string,
};

export type LookupTypeValue = {
  __typename: "LookupTypeValue",
  id: string,
  lookup_type_id: string,
  value: string,
  description: string,
  enabled: boolean,
  start_date?: string | null,
  end_date?: string | null,
  created: string,
  updated: string,
  created_by: string,
  updated_by: string,
};

export type UserActionInput = {
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type UserAction = {
  __typename: "UserAction",
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type CreateUserPrefsInput = {
  username: string,
  global?: string | null,
  selfService?: string | null,
  management?: string | null,
};

export type UserPrefs = {
  __typename: "UserPrefs",
  username: string,
  global?: string | null,
  selfService?: string | null,
  management?: string | null,
};

export type CreateRequestInput = {
  id: string,
  site_id?: string | null,
  site_source_system_id?: string | null,
  reason: string,
  requestor_id: string,
  requestor_source_system_id: string,
  start_date?: string | null,
  end_date?: string | null,
  status?: string | null,
  created_by: string,
};

export type Request = {
  __typename: "Request",
  created: string,
  created_by: string,
  end_date?: string | null,
  id: string,
  last_reminder_date?: string | null,
  reason: string,
  requestor_id: string,
  requestor_source_system_id: string,
  site_id?: string | null,
  site_source_system?: string | null,
  site_source_system_id?: string | null,
  start_date?: string | null,
  status?: string | null,
  status_id?: string | null,
  updated: string,
  updated_by: string,
};

export type CreateVisitorAccessLevelInput = {
  access_level_id: string,
  access_level_name: string,
  access_level_source_system_id: string,
  created_by: string,
  end_date?: string | null,
  id: string,
  permanent_flag: boolean,
  reason: string,
  site_id?: string | null,
  start_date?: string | null,
  status_code_id: string,
  visitor_id: string,
};

export type VisitorAccessLevel = {
  __typename: "VisitorAccessLevel",
  access_level_id: string,
  access_level_name: string,
  access_level_source_system_id: string,
  company?: string | null,
  created: string,
  created_by: string,
  dates_updated?: boolean | null,
  email?: string | null,
  end_date?: string | null,
  first_name?: string | null,
  id: string,
  last_name?: string | null,
  last_reminder_date?: string | null,
  person_id: string,
  person_source_system_id?: string | null,
  permanent_flag: boolean,
  reason: string,
  request_id: string,
  requestor_id: string,
  requestor_source_system_id: string,
  requestuuid?: string | null,
  site_id?: string | null,
  start_date?: string | null,
  status?: string | null,
  status_code_id?: string | null,
  updated: string,
  updated_by: string,
  visitor_id: string,
  visitor_type: string,
};

export type CreateVisitorAccessLevelApprovalInput = {
  id: string,
  visitor_access_level_id: string,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  status_code_id: string,
  denial_reason_id?: string | null,
  notes?: string | null,
  created_by: string,
};

export type VisitorAccessLevelApproval = {
  __typename: "VisitorAccessLevelApproval",
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  created: string,
  created_by: string,
  denial_reason_id?: string | null,
  denial_reason?: string | null,
  id: string,
  notes?: string | null,
  status: string,
  status_code_id: string,
  updated: string,
  updated_by: string,
  visitor_access_level_id: string,
};

export type CreateVisitorActionInput = {
  action_type_id: string,
  additional_info: string,
  created_by: string,
  id: string,
  visitor_id: string,
};

export type VisitorAction = {
  __typename: "VisitorAction",
  id: string,
  visitor_id: string,
  action_type: string,
  action_type_id: string,
  additional_info: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateRequestEscortInput = {
  id: string,
  request_id: string,
  escort_id: string,
  escort_source_system_id: string,
  created_by: string,
};

export type RequestEscort = {
  __typename: "RequestEscort",
  id: string,
  request_id: string,
  escort_id: string,
  escort_source_system_id: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateVisitorInput = {
  badge_requestUUID?: string | null,
  company?: string | null,
  created_by: string,
  dob_month?: number | null,
  dob_day?: number | null,
  email?: string | null,
  first_name?: string | null,
  id: string,
  last_name?: string | null,
  person_id?: string | null,
  person_source_system_id?: string | null,
  phone_number?: string | null,
  request_id?: string | null,
  requestUUID?: string | null,
  status_id?: string | null,
  title?: string | null,
  visitor_pass_assignment_id?: string | null,
  visitor_type_id: string,
};

export type Visitor = {
  __typename: "Visitor",
  badge_id?: string | null,
  badge_requestUUID?: string | null,
  company?: string | null,
  created: string,
  created_by: string,
  dob_month?: number | null,
  dob_day?: number | null,
  email?: string | null,
  first_name?: string | null,
  id: string,
  last_name?: string | null,
  person_id?: string | null,
  person_source_system_id?: string | null,
  phone_number?: string | null,
  request_id?: string | null,
  requestUUID?: string | null,
  status_id?: string | null,
  title?: string | null,
  vendor_day_pass_badge_num?: string | null,
  visitor_type_id: string,
  visitor_pass_assignment_id?: string | null,
  updated: string,
  updated_by: string,
};

export type CreateVisitorAssetInput = {
  approval_status_id: string,
  asset_type_id: string,
  created_by: string,
  denial_reason_id?: string | null,
  description: string,
  end_date?: string | null,
  make?: string | null,
  model?: string | null,
  notes?: string | null,
  permanent_flag: boolean,
  serial_num: string,
  site_id: string,
  site_source_system_id: string,
  start_date?: string | null,
  sticker_num?: string | null,
  visitor_id: string,
};

export type VisitorAsset = {
  __typename: "VisitorAsset",
  approval_status?: string | null,
  approval_status_id: string,
  asset_type_id: string,
  asset_type?: string | null,
  created: string,
  created_by: string,
  denial_reason?: string | null,
  denial_reason_id?: string | null,
  description: string,
  end_date?: string | null,
  id: string,
  make?: string | null,
  model?: string | null,
  notes?: string | null,
  permanent_flag: boolean,
  person_id?: string | null,
  serial_num: string,
  site_id: string,
  site_source_system_id: string,
  start_date?: string | null,
  sticker_num?: string | null,
  updated: string,
  updated_by: string,
  visitor_id: string,
};

export type CreateVisitorAssetApprovalInput = {
  id: string,
  visitor_asset_id: string,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  status_code_id: string,
  denial_reason_id?: string | null,
  notes?: string | null,
  created_by: string,
};

export type VisitorAssetApproval = {
  __typename: "VisitorAssetApproval",
  id: string,
  visitor_asset_id: string,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  status_code_id: string,
  status: string,
  denial_reason_id?: string | null,
  denial_reason?: string | null,
  notes?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateVisitorPassInput = {
  created_by: string,
  badge_id: string,
  badge_source_system_id: string,
  visitor_pass_assignment_id?: string | null,
};

export type VisitorPass = {
  __typename: "VisitorPass",
  id: string,
  badge_id: string,
  badge_source_system_id: string,
  visitor_pass_assignment_id?: string | null,
  created: string,
  updated: string,
  created_by: string,
  updated_by: string,
};

export type CreateVisitorPassAssignmentInput = {
  id: string,
  created_by: string,
};

export type VisitorPassAssignment = {
  __typename: "VisitorPassAssignment",
  id: string,
  created: string,
  updated: string,
  created_by: string,
  updated_by: string,
};

export type DeleteApproverGroupInput = {
  id: string,
  updated_by: string,
};

export type DeleteDelegationInput = {
  id: string,
  updated_by: string,
};

export type DeleteUserPrefsInput = {
  username: string,
};

export type DeleteRequestInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorAccessLevelInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorAccessLevelApprovalInput = {
  id: string,
  updated_by: string,
};

export type DeleteRequestEscortInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorAssetInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorPassInput = {
  id: string,
  updated_by: string,
};

export type DeleteVisitorPassAssignmentInput = {
  id: string,
  updated_by: string,
};

export type UpdateApproverGroupInput = {
  id: string,
  access_level?: string | null,
  approver_group?: string | null,
  updated_by: string,
};

export type UpdateLookupTypeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  updated_by: string,
};

export type UpdateLookupTypeValueInput = {
  id: string,
  value?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  start_date?: string | null,
  end_date?: string | null,
  updated_by: string,
};

export type UpdateUserPrefsInput = {
  username: string,
  global?: string | null,
  selfService?: string | null,
  management?: string | null,
};

export type UpdateRequestInput = {
  end_date?: string | null,
  id: string,
  last_reminder_date?: string | null,
  reason?: string | null,
  requestor_id?: string | null,
  requestor_source_system_id?: string | null,
  site_id?: string | null,
  site_source_system_id?: string | null,
  start_date?: string | null,
  status_id?: string | null,
  updated_by: string,
};

export type UpdateVisitorAccessLevelInput = {
  dates_updated?: boolean | null,
  end_date?: string | null,
  id: string,
  start_date?: string | null,
  status_code_id?: string | null,
  permanent_flag?: boolean | null,
  requestUUID?: string | null,
  updated_by: string,
};

export type UpdateVisitorAccessLevelApprovalInput = {
  id: string,
  visitor_access_level_id: string,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  status_code_id: string,
  denial_reason_id?: string | null,
  notes?: string | null,
  updated_by: string,
};

export type UpdateVisitorAssetApprovalInput = {
  id: string,
  visitor_asset_id: string,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  status_code_id: string,
  denial_reason_id?: string | null,
  notes?: string | null,
  updated_by: string,
};

export type UpdateRequestEscortInput = {
  id: string,
  request_id?: string | null,
  escort_id?: string | null,
  escort_source_system_id?: string | null,
  updated_by: string,
};

export type UpdateVisitorInput = {
  badge_id?: string | null,
  badge_requestUUID?: string | null,
  company?: string | null,
  dob_month?: number | null,
  dob_day?: number | null,
  email?: string | null,
  first_name?: string | null,
  id: string,
  last_name?: string | null,
  person_id?: string | null,
  person_source_system_id?: string | null,
  phone_number?: string | null,
  request_id?: string | null,
  requestUUID?: string | null,
  status_id?: string | null,
  title?: string | null,
  updated_by: string,
  vendor_day_pass_badge_num?: string | null,
  visitor_pass_assignment_id?: string | null,
  visitor_type_id?: string | null,
};

export type UpdateVisitorAssetInput = {
  approval_status_id?: string | null,
  asset_type_id?: string | null,
  description?: string | null,
  denial_reason_id?: string | null,
  end_date?: string | null,
  id: string,
  make?: string | null,
  model?: string | null,
  notes?: string | null,
  permanent_flag?: boolean | null,
  serial_num?: string | null,
  site_id: string,
  site_source_system_id: string,
  start_date?: string | null,
  sticker_num?: string | null,
  updated_by: string,
  visitor_id?: string | null,
};

export type UpdateVisitorPassInput = {
  id: string,
  badge_id: string,
  badge_source_system_id: string,
  visitor_pass_assignment_id?: string | null,
  updated_by: string,
};

export type GetAccessLevelsForPSIDInput = {
  methodName: string,
  params?: GetAccessLevelsForPSIDParamsInput | null,
};

export type GetAccessLevelsForPSIDParamsInput = {
  employeeId: string,
  Orderby: string,
  OrderbyDirection: OrderByDirectionEnum,
  PageNumber: number,
  RecordsByPage: number,
};

export enum OrderByDirectionEnum {
  a = "a",
  d = "d",
}


export type AccessLevel = {
  __typename: "AccessLevel",
  ACCESSLEVEL_ACTIVATE?: string | null,
  ACCESSLEVEL_DEACTIVATE?: string | null,
  ACCESSLEVEL_NAME: string,
  ACCLVLID: string,
};

export type AccessRequestDetails = {
  __typename: "AccessRequestDetails",
  request_created?: string | null,
  request_created_by?: string | null,
  request_end_date?: string | null,
  request_escort_created?: string | null,
  request_escort_created_by?: string | null,
  request_escort_id?: string | null,
  request_escort_person_id?: string | null,
  request_escort_updated?: string | null,
  request_escort_updated_by?: string | null,
  request_id?: string | null,
  request_reason?: string | null,
  request_site_id?: string | null,
  request_start_date?: string | null,
  request_status?: string | null,
  request_updated?: string | null,
  request_updated_by?: string | null,
  requestor_id?: string | null,
  track_by?: string | null,
  visitor_access_level_approval_id?: string | null,
  visitor_access_level_approval_person_id?: string | null,
  visitor_access_level_approval_created?: string | null,
  visitor_access_level_approval_created_by?: string | null,
  visitor_access_level_approval_status?: string | null,
  visitor_access_level_approval_updated?: string | null,
  visitor_access_level_approval_updated_by?: string | null,
  visitor_access_level_created?: string | null,
  visitor_access_level_created_by?: string | null,
  visitor_access_level_denial_reason?: string | null,
  visitor_access_level_end_date?: string | null,
  visitor_access_level_id?: string | null,
  visitor_access_level_name?: string | null,
  visitor_access_level_start_date?: string | null,
  visitor_access_level_status?: string | null,
  visitor_access_level_updated?: string | null,
  visitor_access_level_updated_by?: string | null,
  visitor_company?: string | null,
  visitor_created?: string | null,
  visitor_created_by?: string | null,
  visitor_email?: string | null,
  visitor_first_name?: string | null,
  visitor_id?: string | null,
  visitor_last_name?: string | null,
  visitor_person_id?: string | null,
  visitor_phone_number?: string | null,
  visitor_status?: string | null,
  visitor_type?: string | null,
  visitor_updated?: string | null,
  visitor_updated_by?: string | null,
};

export type GetEmployeeDetailsForLoginInput = {
  methodName: string,
  params?: GetEmployeeDetailsForLoginParamsInput | null,
};

export type GetEmployeeDetailsForLoginParamsInput = {
  login: string,
};

export type EmployeeDetails = {
  __typename: "EmployeeDetails",
  status: string,
  email?: string | null,
  firstName: string,
  id: string,
  idHash: string,
  jobLevel?: string | null,
  lastName: string,
  locationName?: string | null,
  region?: string | null,
  siteCode: string,
  title: string,
  username: string,
};

export type GetEmployeeActiveBadgesInput = {
  methodName: string,
  params?: GetEmployeeActiveBadgesParamsInput | null,
};

export type GetEmployeeActiveBadgesParamsInput = {
  emplId: string,
  region: string,
};

export type EmployeeActiveBadges = {
  __typename: "EmployeeActiveBadges",
  badgeId: string,
};

export type GetEmpWelcomeLinkInput = {
  methodName: string,
  params?: GetEmpWelcomeLinkParamsInput | null,
};

export type GetEmpWelcomeLinkParamsInput = {
  empid: number,
};

export type EmpWelcomeLink = {
  __typename: "EmpWelcomeLink",
  empid: number,
  welcome_guid: string,
};

export type GetEmpWelcomeLinkForWelcomeIdInput = {
  methodName: string,
  params?: GetEmpWelcomeLinkForWelcomeIdParamsInput | null,
};

export type GetEmpWelcomeLinkForWelcomeIdParamsInput = {
  visitorId: string,
};

export type GetBadgeAssignmentInput = {
  methodName: string,
  params?: GetBadgeAssignmentParamsInput | null,
};

export type GetBadgeAssignmentParamsInput = {
  badgeId: string,
};

export type BadgeAssignment = {
  __typename: "BadgeAssignment",
  activate: string,
  badge_id: string,
  badge_status_name: string,
  badge_type_name: string,
  deactivate: string,
  email?: string | null,
  empid: number,
  employee_id?: number | null,
  firstname?: string | null,
  lastname?: string | null,
  status: number,
  type: number,
};

export type UUID = {
  __typename: "UUID",
  uuid: string,
};

export type GetRequestsForSiteInput = {
  last_ctid: string,
  limit: number,
  offset: number,
  site_id: string,
  site_source_system_id: string,
};

export type RequestForSite = {
  __typename: "RequestForSite",
  id: string,
  ctid: string,
  site_id: string,
  site_source_system_id: string,
  reason: string,
  requestor_id: string,
  requestor_source_system_id: string,
  start_date?: string | null,
  end_date?: string | null,
  status_id?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type VisitorRequest = {
  __typename: "VisitorRequest",
  approvals_needed: string,
  badge_id?: string | null,
  company?: string | null,
  created: string,
  created_by: string,
  dob_month?: number | null,
  dob_day?: number | null,
  email?: string | null,
  end_date?: string | null,
  escort1?: string | null,
  escort2?: string | null,
  escort3?: string | null,
  escort4?: string | null,
  escort5?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  person_id?: string | null,
  person_source_system_id?: string | null,
  phone_number?: string | null,
  reason?: string | null,
  request_id?: string | null,
  requestor_id?: string | null,
  requestor_source_system_id?: string | null,
  site_id: string,
  start_date?: string | null,
  status: string,
  title?: string | null,
  updated: string,
  updated_by: string,
  visitor_id: string,
  vendor_day_pass_badge_num?: string | null,
  visitor_pass_assignment_id?: string | null,
  visitor_type: string,
  visitor_type_id: string,
};

export type GetVisitorPasses = {
  last_ctid: string,
  limit: number,
  offset: number,
};

export type AccessLevelApproverGroup = {
  __typename: "AccessLevelApproverGroup",
  approver_group: string,
};

export type ListAccessLevelsForSiteInput = {
  methodName: string,
  params?: ListAccessLevelsForSiteParamsInput | null,
};

export type ListAccessLevelsForSiteParamsInput = {
  siteCode: string,
};

export type AccessLevelForSite = {
  __typename: "AccessLevelForSite",
  AccessLevelID?: number | null,
  AccessLevelName: string,
};

export type listApproverGroupsInput = {
  limit: number,
  offset: number,
};

export type ListEmployeesInput = {
  methodName: string,
  params?: ListEmployeesParamsInput | null,
};

export type ListEmployeesParamsInput = {
  firstName: string,
  lastName: string,
  login: string,
  emplId: string,
};

export type listLookupTypeValuesForTypeNameInput = {
  limit: number,
  lookup_type_name: string,
  offset: number,
};

export type ListSitesInput = {
  methodName: string,
};

export type Site = {
  __typename: "Site",
  SiteCode: string,
};

export type ListSupervisorReportsInput = {
  methodName: string,
  params: ListSupervisorReportsParamsInput,
};

export type ListSupervisorReportsParamsInput = {
  emplId: string,
};

export type Report = {
  __typename: "Report",
  FinalPSID: string,
  FinalSupID?: string | null,
  SupervisorHeightAbove: number,
  inserted_datetime_utc: string,
  username?: string | null,
};

export type VisitorAccessLevelApprovalForRequest = {
  __typename: "VisitorAccessLevelApprovalForRequest",
  access_level_id?: string | null,
  access_level_name?: string | null,
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  company?: string | null,
  created: string,
  created_by: string,
  denial_reason_id?: string | null,
  denial_reason?: string | null,
  end_date?: string | null,
  first_name?: string | null,
  email?: string | null,
  id: string,
  last_name?: string | null,
  notes?: string | null,
  permanent_flag: boolean,
  person_id?: string | null,
  phone_number?: string | null,
  request_end_date?: string | null,
  request_id: string,
  request_reason?: string | null,
  request_site_id?: string | null,
  request_start_date?: string | null,
  site_id?: string | null,
  start_date?: string | null,
  status: string,
  status_code_id: string,
  title?: string | null,
  updated: string,
  updated_by: string,
  visitor_access_level_id: string,
  visitor_id: string,
  visitor_type: string,
};

export type VisitorAssetApprovalForVisitorAsset = {
  __typename: "VisitorAssetApprovalForVisitorAsset",
  approver_id: string,
  approver_email_address?: string | null,
  approver_source_system_id: string,
  asset_type_id: string,
  asset_type: string,
  company?: string | null,
  created: string,
  created_by: string,
  denial_reason_id?: string | null,
  denial_reason?: string | null,
  description: string,
  dob_month?: string | null,
  dob_day?: string | null,
  email?: string | null,
  end_date?: string | null,
  first_name?: string | null,
  id: string,
  last_name?: string | null,
  notes?: string | null,
  permanent_flag: boolean,
  person_id: string,
  phone_number?: string | null,
  serial_num: string,
  site_id?: string | null,
  site_source_system_id?: string | null,
  start_date?: string | null,
  status_code_id: string,
  status: string,
  title?: string | null,
  updated: string,
  updated_by: string,
  visitor_asset_id: string,
  visitor_id: string,
};

export type SNSResponse = {
  __typename: "SNSResponse",
  MessageId: string,
  SequenceNumber?: string | null,
};

export type DelegationPrivilegeInput = {
  delegationType: string,
  end_date?: string | null,
  fromEmailAddress: string,
  fromUsername: string,
  justification: string,
  permanent_flag: boolean,
  start_date?: string | null,
  toEmailAddress: string,
  toUsername: string,
};

export type VisitorDepartedInput = {
  site: string,
  visitor_id: string,
  badge_id?: string | null,
  company?: string | null,
  escorts?: Array< EscortInput | null > | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  person_id?: string | null,
  phone_number?: string | null,
  request_id?: string | null,
  vendor_day_pass_badge_num?: string | null,
  visitor_type: string,
  checkOutTime: number,
};

export type EscortInput = {
  escortName?: string | null,
  escortEmpId?: string | null,
};

export type VisitorReceivedInput = {
  site: string,
  visitor_id: string,
  badge_id?: string | null,
  company?: string | null,
  escorts?: Array< EscortInput | null > | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  person_id?: string | null,
  phone_number?: string | null,
  request_id?: string | null,
  vendor_day_pass_badge_num?: string | null,
  visitor_type: string,
  checkInTime: number,
};

export type AccessControlRequestStatusMutationVariables = {
  input: AccessControlRequestStatusInput,
};

export type AccessControlRequestStatusMutation = {
  accessControlRequestStatus?:  {
    __typename: "AccessControlRequestStatusResponse",
    error?: string | null,
    message?: string | null,
    requestUUID?: string | null,
    statusCode: number,
  } | null,
};

export type BadgeAccessLevelMutationVariables = {
  input: BadgeAccessLevelRequestInput,
};

export type BadgeAccessLevelMutation = {
  badgeAccessLevel?:  {
    __typename: "BadgeAccessLevelRequestResponse",
    statusCode: number,
    error?: string | null,
    message?: string | null,
    requestUUID?: string | null,
  } | null,
};

export type BadgeAssignmentMutationVariables = {
  input: BadgeAssignmentInput,
};

export type BadgeAssignmentMutation = {
  badgeAssignment?:  {
    __typename: "BadgeAssignmentRequestResponse",
    statusCode: number,
    error?: string | null,
    message?: string | null,
    requestUUID?: string | null,
  } | null,
};

export type CardholderMutationVariables = {
  input: CardholderRequestInput,
};

export type CardholderMutation = {
  cardholder?:  {
    __typename: "CardholderRequestResponse",
    statusCode: number,
    error?: string | null,
    message?: string | null,
    requestUUID?: string | null,
  } | null,
};

export type CreateApproverGroupMutationVariables = {
  input: CreateApproverGroupInput,
};

export type CreateApproverGroupMutation = {
  createApproverGroup?:  {
    __typename: "ApproverGroup",
    id: string,
    access_level: string,
    approver_group: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type CreateDelegationMutationVariables = {
  input: CreateDelegationInput,
};

export type CreateDelegationMutation = {
  createDelegation?:  {
    __typename: "Delegation",
    created: string,
    created_by: string,
    delegator_id: string,
    delegator_username?: string | null,
    delegatee_id: string,
    delegatee_username?: string | null,
    delegation_type_id: string,
    delegation_type?: string | null,
    end_date?: string | null,
    justification: string,
    id: string,
    permanent_flag: boolean,
    start_date?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateLookupTypeMutationVariables = {
  input: CreateLookupTypeInput,
};

export type CreateLookupTypeMutation = {
  createLookupType?:  {
    __typename: "LookupType",
    id: string,
    name: string,
    description: string,
    enabled: boolean,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type CreateLookupTypeValueMutationVariables = {
  input: CreateLookupTypeValueInput,
};

export type CreateLookupTypeValueMutation = {
  createLookupTypeValue?:  {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: UserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    parameters?: string | null,
    timestamp: string,
    ttl: number,
    username: string,
  } | null,
};

export type CreateUserPrefsMutationVariables = {
  input: CreateUserPrefsInput,
};

export type CreateUserPrefsMutation = {
  createUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    global?: string | null,
    selfService?: string | null,
    management?: string | null,
  } | null,
};

export type CreateRequestMutationVariables = {
  input: CreateRequestInput,
};

export type CreateRequestMutation = {
  createRequest?:  {
    __typename: "Request",
    created: string,
    created_by: string,
    end_date?: string | null,
    id: string,
    last_reminder_date?: string | null,
    reason: string,
    requestor_id: string,
    requestor_source_system_id: string,
    site_id?: string | null,
    site_source_system?: string | null,
    site_source_system_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateVisitorAccessLevelMutationVariables = {
  input: CreateVisitorAccessLevelInput,
};

export type CreateVisitorAccessLevelMutation = {
  createVisitorAccessLevel?:  {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null,
};

export type CreateVisitorAccessLevelApprovalMutationVariables = {
  input: CreateVisitorAccessLevelApprovalInput,
};

export type CreateVisitorAccessLevelApprovalMutation = {
  createVisitorAccessLevelApproval?:  {
    __typename: "VisitorAccessLevelApproval",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    id: string,
    notes?: string | null,
    status: string,
    status_code_id: string,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
  } | null,
};

export type CreateVisitorActionMutationVariables = {
  input: CreateVisitorActionInput,
};

export type CreateVisitorActionMutation = {
  createVisitorAction?:  {
    __typename: "VisitorAction",
    id: string,
    visitor_id: string,
    action_type: string,
    action_type_id: string,
    additional_info: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateRequestEscortMutationVariables = {
  input: CreateRequestEscortInput,
};

export type CreateRequestEscortMutation = {
  createRequestEscort?:  {
    __typename: "RequestEscort",
    id: string,
    request_id: string,
    escort_id: string,
    escort_source_system_id: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateVisitorMutationVariables = {
  input: CreateVisitorInput,
};

export type CreateVisitorMutation = {
  createVisitor?:  {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateVisitorAssetMutationVariables = {
  input: CreateVisitorAssetInput,
};

export type CreateVisitorAssetMutation = {
  createVisitorAsset?:  {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null,
};

export type CreateVisitorAssetApprovalMutationVariables = {
  input: CreateVisitorAssetApprovalInput,
};

export type CreateVisitorAssetApprovalMutation = {
  createVisitorAssetApproval?:  {
    __typename: "VisitorAssetApproval",
    id: string,
    visitor_asset_id: string,
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    status_code_id: string,
    status: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    notes?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateVisitorPassMutationVariables = {
  input: CreateVisitorPassInput,
};

export type CreateVisitorPassMutation = {
  createVisitorPass?:  {
    __typename: "VisitorPass",
    id: string,
    badge_id: string,
    badge_source_system_id: string,
    visitor_pass_assignment_id?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type CreateVisitorPassAssignmentMutationVariables = {
  input: CreateVisitorPassAssignmentInput,
};

export type CreateVisitorPassAssignmentMutation = {
  createVisitorPassAssignment?:  {
    __typename: "VisitorPassAssignment",
    id: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type DeleteApproverGroupMutationVariables = {
  input: DeleteApproverGroupInput,
};

export type DeleteApproverGroupMutation = {
  deleteApproverGroup?:  {
    __typename: "ApproverGroup",
    id: string,
    access_level: string,
    approver_group: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type DeleteDelegationMutationVariables = {
  input: DeleteDelegationInput,
};

export type DeleteDelegationMutation = {
  deleteDelegation?:  {
    __typename: "Delegation",
    created: string,
    created_by: string,
    delegator_id: string,
    delegator_username?: string | null,
    delegatee_id: string,
    delegatee_username?: string | null,
    delegation_type_id: string,
    delegation_type?: string | null,
    end_date?: string | null,
    justification: string,
    id: string,
    permanent_flag: boolean,
    start_date?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteUserPrefsMutationVariables = {
  input: DeleteUserPrefsInput,
};

export type DeleteUserPrefsMutation = {
  deleteUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    global?: string | null,
    selfService?: string | null,
    management?: string | null,
  } | null,
};

export type DeleteRequestMutationVariables = {
  input: DeleteRequestInput,
};

export type DeleteRequestMutation = {
  deleteRequest?:  {
    __typename: "Request",
    created: string,
    created_by: string,
    end_date?: string | null,
    id: string,
    last_reminder_date?: string | null,
    reason: string,
    requestor_id: string,
    requestor_source_system_id: string,
    site_id?: string | null,
    site_source_system?: string | null,
    site_source_system_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteVisitorAccessLevelMutationVariables = {
  input: DeleteVisitorAccessLevelInput,
};

export type DeleteVisitorAccessLevelMutation = {
  deleteVisitorAccessLevel?:  {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null,
};

export type DeleteVisitorAccessLevelApprovalMutationVariables = {
  input: DeleteVisitorAccessLevelApprovalInput,
};

export type DeleteVisitorAccessLevelApprovalMutation = {
  deleteVisitorAccessLevelApproval?:  {
    __typename: "VisitorAccessLevelApproval",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    id: string,
    notes?: string | null,
    status: string,
    status_code_id: string,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
  } | null,
};

export type DeleteRequestEscortMutationVariables = {
  input: DeleteRequestEscortInput,
};

export type DeleteRequestEscortMutation = {
  deleteRequestEscort?:  {
    __typename: "RequestEscort",
    id: string,
    request_id: string,
    escort_id: string,
    escort_source_system_id: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteVisitorMutationVariables = {
  input: DeleteVisitorInput,
};

export type DeleteVisitorMutation = {
  deleteVisitor?:  {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteVisitorAssetMutationVariables = {
  input: DeleteVisitorAssetInput,
};

export type DeleteVisitorAssetMutation = {
  deleteVisitorAsset?:  {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null,
};

export type DeleteVisitorPassMutationVariables = {
  input: DeleteVisitorPassInput,
};

export type DeleteVisitorPassMutation = {
  deleteVisitorPass?:  {
    __typename: "VisitorPass",
    id: string,
    badge_id: string,
    badge_source_system_id: string,
    visitor_pass_assignment_id?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type DeleteVisitorPassAssignmentMutationVariables = {
  input: DeleteVisitorPassAssignmentInput,
};

export type DeleteVisitorPassAssignmentMutation = {
  deleteVisitorPassAssignment?:  {
    __typename: "VisitorPassAssignment",
    id: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type UpdateApproverGroupMutationVariables = {
  input: UpdateApproverGroupInput,
};

export type UpdateApproverGroupMutation = {
  updateApproverGroup?:  {
    __typename: "ApproverGroup",
    id: string,
    access_level: string,
    approver_group: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type UpdateLookupTypeMutationVariables = {
  input: UpdateLookupTypeInput,
};

export type UpdateLookupTypeMutation = {
  updateLookupType?:  {
    __typename: "LookupType",
    id: string,
    name: string,
    description: string,
    enabled: boolean,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type UpdateLookupTypeValueMutationVariables = {
  input: UpdateLookupTypeValueInput,
};

export type UpdateLookupTypeValueMutation = {
  updateLookupTypeValue?:  {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type UpdateUserPrefsMutationVariables = {
  input: UpdateUserPrefsInput,
};

export type UpdateUserPrefsMutation = {
  updateUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    global?: string | null,
    selfService?: string | null,
    management?: string | null,
  } | null,
};

export type UpdateRequestMutationVariables = {
  input: UpdateRequestInput,
};

export type UpdateRequestMutation = {
  updateRequest?:  {
    __typename: "Request",
    created: string,
    created_by: string,
    end_date?: string | null,
    id: string,
    last_reminder_date?: string | null,
    reason: string,
    requestor_id: string,
    requestor_source_system_id: string,
    site_id?: string | null,
    site_source_system?: string | null,
    site_source_system_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateVisitorAccessLevelMutationVariables = {
  input: UpdateVisitorAccessLevelInput,
};

export type UpdateVisitorAccessLevelMutation = {
  updateVisitorAccessLevel?:  {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null,
};

export type UpdateVisitorAccessLevelApprovalMutationVariables = {
  input: UpdateVisitorAccessLevelApprovalInput,
};

export type UpdateVisitorAccessLevelApprovalMutation = {
  updateVisitorAccessLevelApproval?:  {
    __typename: "VisitorAccessLevelApproval",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    id: string,
    notes?: string | null,
    status: string,
    status_code_id: string,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
  } | null,
};

export type UpdateVisitorAssetApprovalMutationVariables = {
  input: UpdateVisitorAssetApprovalInput,
};

export type UpdateVisitorAssetApprovalMutation = {
  updateVisitorAssetApproval?:  {
    __typename: "VisitorAssetApproval",
    id: string,
    visitor_asset_id: string,
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    status_code_id: string,
    status: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    notes?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateRequestEscortMutationVariables = {
  input: UpdateRequestEscortInput,
};

export type UpdateRequestEscortMutation = {
  updateRequestEscort?:  {
    __typename: "RequestEscort",
    id: string,
    request_id: string,
    escort_id: string,
    escort_source_system_id: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateVisitorMutationVariables = {
  input: UpdateVisitorInput,
};

export type UpdateVisitorMutation = {
  updateVisitor?:  {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateVisitorAssetMutationVariables = {
  input: UpdateVisitorAssetInput,
};

export type UpdateVisitorAssetMutation = {
  updateVisitorAsset?:  {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null,
};

export type UpdateVisitorPassMutationVariables = {
  input: UpdateVisitorPassInput,
};

export type UpdateVisitorPassMutation = {
  updateVisitorPass?:  {
    __typename: "VisitorPass",
    id: string,
    badge_id: string,
    badge_source_system_id: string,
    visitor_pass_assignment_id?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type GetAccessLevelsForPSIDQueryVariables = {
  input: GetAccessLevelsForPSIDInput,
};

export type GetAccessLevelsForPSIDQuery = {
  getAccessLevelsForPSID?:  Array< {
    __typename: "AccessLevel",
    ACCESSLEVEL_ACTIVATE?: string | null,
    ACCESSLEVEL_DEACTIVATE?: string | null,
    ACCESSLEVEL_NAME: string,
    ACCLVLID: string,
  } | null > | null,
};

export type GetAccessRequestDetailsQueryVariables = {
  request_id: string,
  limit: number,
  offset: number,
};

export type GetAccessRequestDetailsQuery = {
  getAccessRequestDetails?:  Array< {
    __typename: "AccessRequestDetails",
    request_created?: string | null,
    request_created_by?: string | null,
    request_end_date?: string | null,
    request_escort_created?: string | null,
    request_escort_created_by?: string | null,
    request_escort_id?: string | null,
    request_escort_person_id?: string | null,
    request_escort_updated?: string | null,
    request_escort_updated_by?: string | null,
    request_id?: string | null,
    request_reason?: string | null,
    request_site_id?: string | null,
    request_start_date?: string | null,
    request_status?: string | null,
    request_updated?: string | null,
    request_updated_by?: string | null,
    requestor_id?: string | null,
    track_by?: string | null,
    visitor_access_level_approval_id?: string | null,
    visitor_access_level_approval_person_id?: string | null,
    visitor_access_level_approval_created?: string | null,
    visitor_access_level_approval_created_by?: string | null,
    visitor_access_level_approval_status?: string | null,
    visitor_access_level_approval_updated?: string | null,
    visitor_access_level_approval_updated_by?: string | null,
    visitor_access_level_created?: string | null,
    visitor_access_level_created_by?: string | null,
    visitor_access_level_denial_reason?: string | null,
    visitor_access_level_end_date?: string | null,
    visitor_access_level_id?: string | null,
    visitor_access_level_name?: string | null,
    visitor_access_level_start_date?: string | null,
    visitor_access_level_status?: string | null,
    visitor_access_level_updated?: string | null,
    visitor_access_level_updated_by?: string | null,
    visitor_company?: string | null,
    visitor_created?: string | null,
    visitor_created_by?: string | null,
    visitor_email?: string | null,
    visitor_first_name?: string | null,
    visitor_id?: string | null,
    visitor_last_name?: string | null,
    visitor_person_id?: string | null,
    visitor_phone_number?: string | null,
    visitor_status?: string | null,
    visitor_type?: string | null,
    visitor_updated?: string | null,
    visitor_updated_by?: string | null,
  } | null > | null,
};

export type GetEmployeeDetailsForLoginQueryVariables = {
  input: GetEmployeeDetailsForLoginInput,
};

export type GetEmployeeDetailsForLoginQuery = {
  getEmployeeDetailsForLogin?:  {
    __typename: "EmployeeDetails",
    status: string,
    email?: string | null,
    firstName: string,
    id: string,
    idHash: string,
    jobLevel?: string | null,
    lastName: string,
    locationName?: string | null,
    region?: string | null,
    siteCode: string,
    title: string,
    username: string,
  } | null,
};

export type GetEmployeeActiveBadgesQueryVariables = {
  input: GetEmployeeActiveBadgesInput,
};

export type GetEmployeeActiveBadgesQuery = {
  getEmployeeActiveBadges?:  Array< {
    __typename: "EmployeeActiveBadges",
    badgeId: string,
  } | null > | null,
};

export type GetEmpWelcomeLinkQueryVariables = {
  input: GetEmpWelcomeLinkInput,
};

export type GetEmpWelcomeLinkQuery = {
  getEmpWelcomeLink?:  {
    __typename: "EmpWelcomeLink",
    empid: number,
    welcome_guid: string,
  } | null,
};

export type GetEmpWelcomeLinkForWelcomeIdQueryVariables = {
  input: GetEmpWelcomeLinkForWelcomeIdInput,
};

export type GetEmpWelcomeLinkForWelcomeIdQuery = {
  getEmpWelcomeLinkForWelcomeId?:  {
    __typename: "EmpWelcomeLink",
    empid: number,
    welcome_guid: string,
  } | null,
};

export type GetApproverGroupQueryVariables = {
  id: string,
};

export type GetApproverGroupQuery = {
  getApproverGroup?:  {
    __typename: "ApproverGroup",
    id: string,
    access_level: string,
    approver_group: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type GetBadgeAssignmentQueryVariables = {
  input?: GetBadgeAssignmentInput | null,
};

export type GetBadgeAssignmentQuery = {
  getBadgeAssignment?:  {
    __typename: "BadgeAssignment",
    activate: string,
    badge_id: string,
    badge_status_name: string,
    badge_type_name: string,
    deactivate: string,
    email?: string | null,
    empid: number,
    employee_id?: number | null,
    firstname?: string | null,
    lastname?: string | null,
    status: number,
    type: number,
  } | null,
};

export type GetLookupTypeQueryVariables = {
  id: string,
};

export type GetLookupTypeQuery = {
  getLookupType?:  {
    __typename: "LookupType",
    id: string,
    name: string,
    description: string,
    enabled: boolean,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type GetLookupTypesQueryVariables = {
};

export type GetLookupTypesQuery = {
  getLookupTypes?:  Array< {
    __typename: "LookupType",
    id: string,
    name: string,
    description: string,
    enabled: boolean,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type GetLookupTypeValueQueryVariables = {
  id: string,
};

export type GetLookupTypeValueQuery = {
  getLookupTypeValue?:  {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type GetLookupTypeValuesQueryVariables = {
  lookup_type_id: string,
};

export type GetLookupTypeValuesQuery = {
  getLookupTypeValues?:  Array< {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type GetLookupTypeValueForTypeNameAndValueQueryVariables = {
  lookup_type_name: string,
  value: string,
};

export type GetLookupTypeValueForTypeNameAndValueQuery = {
  getLookupTypeValueForTypeNameAndValue?:  {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null,
};

export type GetLookupTypeValuesForTypeNameQueryVariables = {
  lookup_type_name: string,
};

export type GetLookupTypeValuesForTypeNameQuery = {
  getLookupTypeValuesForTypeName?:  Array< {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type GetUUIDQueryVariables = {
};

export type GetUUIDQuery = {
  getUUID?:  {
    __typename: "UUID",
    uuid: string,
  } | null,
};

export type GetUserActionsQueryVariables = {
  username: string,
};

export type GetUserActionsQuery = {
  getUserActions?:  Array< {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    parameters?: string | null,
    timestamp: string,
    ttl: number,
    username: string,
  } | null > | null,
};

export type GetUserPrefsQueryVariables = {
  username: string,
};

export type GetUserPrefsQuery = {
  getUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    global?: string | null,
    selfService?: string | null,
    management?: string | null,
  } | null,
};

export type GetRequestQueryVariables = {
  id: string,
};

export type GetRequestQuery = {
  getRequest?:  {
    __typename: "Request",
    created: string,
    created_by: string,
    end_date?: string | null,
    id: string,
    last_reminder_date?: string | null,
    reason: string,
    requestor_id: string,
    requestor_source_system_id: string,
    site_id?: string | null,
    site_source_system?: string | null,
    site_source_system_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetVisitorAccessLevelQueryVariables = {
  id: string,
};

export type GetVisitorAccessLevelQuery = {
  getVisitorAccessLevel?:  {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null,
};

export type GetVisitorAccessLevelForRequestUUIDQueryVariables = {
  requestuuid: string,
};

export type GetVisitorAccessLevelForRequestUUIDQuery = {
  getVisitorAccessLevelForRequestUUID?:  {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null,
};

export type GetVisitorAccessLevelApprovalQueryVariables = {
  id: string,
};

export type GetVisitorAccessLevelApprovalQuery = {
  getVisitorAccessLevelApproval?:  {
    __typename: "VisitorAccessLevelApproval",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    id: string,
    notes?: string | null,
    status: string,
    status_code_id: string,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
  } | null,
};

export type GetRequestsForSiteQueryVariables = {
  input?: GetRequestsForSiteInput | null,
};

export type GetRequestsForSiteQuery = {
  getRequestsForSite?:  Array< {
    __typename: "RequestForSite",
    id: string,
    ctid: string,
    site_id: string,
    site_source_system_id: string,
    reason: string,
    requestor_id: string,
    requestor_source_system_id: string,
    start_date?: string | null,
    end_date?: string | null,
    status_id?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type GetRequestEscortQueryVariables = {
  id: string,
};

export type GetRequestEscortQuery = {
  getRequestEscort?:  {
    __typename: "RequestEscort",
    id: string,
    request_id: string,
    escort_id: string,
    escort_source_system_id: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetVisitorQueryVariables = {
  id: string,
};

export type GetVisitorQuery = {
  getVisitor?:  {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetVisitorRequestForNameAndVendorDayPassBadgeNumQueryVariables = {
  first_name: string,
  last_name: string,
  vendor_day_pass_badge_num: string,
};

export type GetVisitorRequestForNameAndVendorDayPassBadgeNumQuery = {
  getVisitorRequestForNameAndVendorDayPassBadgeNum?:  {
    __typename: "VisitorRequest",
    approvals_needed: string,
    badge_id?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    end_date?: string | null,
    escort1?: string | null,
    escort2?: string | null,
    escort3?: string | null,
    escort4?: string | null,
    escort5?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    reason?: string | null,
    request_id?: string | null,
    requestor_id?: string | null,
    requestor_source_system_id?: string | null,
    site_id: string,
    start_date?: string | null,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    vendor_day_pass_badge_num?: string | null,
    visitor_pass_assignment_id?: string | null,
    visitor_type: string,
    visitor_type_id: string,
  } | null,
};

export type GetVisitorRequestForVendorDayPassBadgeNumQueryVariables = {
  vendor_day_pass_badge_num: string,
  visitor_status: string,
};

export type GetVisitorRequestForVendorDayPassBadgeNumQuery = {
  getVisitorRequestForVendorDayPassBadgeNum?:  {
    __typename: "VisitorRequest",
    approvals_needed: string,
    badge_id?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    end_date?: string | null,
    escort1?: string | null,
    escort2?: string | null,
    escort3?: string | null,
    escort4?: string | null,
    escort5?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    reason?: string | null,
    request_id?: string | null,
    requestor_id?: string | null,
    requestor_source_system_id?: string | null,
    site_id: string,
    start_date?: string | null,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    vendor_day_pass_badge_num?: string | null,
    visitor_pass_assignment_id?: string | null,
    visitor_type: string,
    visitor_type_id: string,
  } | null,
};

export type GetVisitorByPersonIdQueryVariables = {
  person_id: string,
  person_source_system_id?: string | null,
};

export type GetVisitorByPersonIdQuery = {
  getVisitorByPersonId?:  {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetVisitorAssetQueryVariables = {
  id: string,
};

export type GetVisitorAssetQuery = {
  getVisitorAsset?:  {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null,
};

export type GetVisitorAssetsForVisitorQueryVariables = {
  visitor_id: string,
};

export type GetVisitorAssetsForVisitorQuery = {
  getVisitorAssetsForVisitor?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type GetVisitorsForRequestQueryVariables = {
  request_id: string,
};

export type GetVisitorsForRequestQuery = {
  getVisitorsForRequest?:  Array< {
    __typename: "Visitor",
    badge_id?: string | null,
    badge_requestUUID?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    request_id?: string | null,
    requestUUID?: string | null,
    status_id?: string | null,
    title?: string | null,
    vendor_day_pass_badge_num?: string | null,
    visitor_type_id: string,
    visitor_pass_assignment_id?: string | null,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type GetVisitorPassesQueryVariables = {
  input: GetVisitorPasses,
};

export type GetVisitorPassesQuery = {
  getVisitorPasses?:  Array< {
    __typename: "VisitorPass",
    id: string,
    badge_id: string,
    badge_source_system_id: string,
    visitor_pass_assignment_id?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type GetVisitorPassAssignmentQueryVariables = {
  id: string,
};

export type GetVisitorPassAssignmentQuery = {
  getVisitorPassAssignment?:  Array< {
    __typename: "VisitorPassAssignment",
    id: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type GetRequestEscortsForRequestQueryVariables = {
  request_id: string,
};

export type GetRequestEscortsForRequestQuery = {
  getRequestEscortsForRequest?:  Array< {
    __typename: "RequestEscort",
    id: string,
    request_id: string,
    escort_id: string,
    escort_source_system_id: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListAccessLevelApproverGroupsQueryVariables = {
  accessLevelName: string,
};

export type ListAccessLevelApproverGroupsQuery = {
  listAccessLevelApproverGroups?:  Array< {
    __typename: "AccessLevelApproverGroup",
    approver_group: string,
  } | null > | null,
};

export type ListAccessLevelsForSiteQueryVariables = {
  input?: ListAccessLevelsForSiteInput | null,
};

export type ListAccessLevelsForSiteQuery = {
  listAccessLevelsForSite?:  Array< {
    __typename: "AccessLevelForSite",
    AccessLevelID?: number | null,
    AccessLevelName: string,
  } | null > | null,
};

export type ListApproverGroupMembersQueryVariables = {
  input: string,
};

export type ListApproverGroupMembersQuery = {
  listApproverGroupMembers?: string | null,
};

export type ListApproverGroupsQueryVariables = {
  input?: listApproverGroupsInput | null,
};

export type ListApproverGroupsQuery = {
  listApproverGroups?:  Array< {
    __typename: "ApproverGroup",
    id: string,
    access_level: string,
    approver_group: string,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type ListDelegationsByDelegateeIDQueryVariables = {
  delegatee_id?: string | null,
};

export type ListDelegationsByDelegateeIDQuery = {
  listDelegationsByDelegateeID?:  Array< {
    __typename: "Delegation",
    created: string,
    created_by: string,
    delegator_id: string,
    delegator_username?: string | null,
    delegatee_id: string,
    delegatee_username?: string | null,
    delegation_type_id: string,
    delegation_type?: string | null,
    end_date?: string | null,
    justification: string,
    id: string,
    permanent_flag: boolean,
    start_date?: string | null,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDelegationsByDelegatorIDQueryVariables = {
  delegator_id?: string | null,
};

export type ListDelegationsByDelegatorIDQuery = {
  listDelegationsByDelegatorID?:  Array< {
    __typename: "Delegation",
    created: string,
    created_by: string,
    delegator_id: string,
    delegator_username?: string | null,
    delegatee_id: string,
    delegatee_username?: string | null,
    delegation_type_id: string,
    delegation_type?: string | null,
    end_date?: string | null,
    justification: string,
    id: string,
    permanent_flag: boolean,
    start_date?: string | null,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDuplicateVisitorAssetsQueryVariables = {
  serial_num: string,
  type?: string | null,
  username?: string | null,
};

export type ListDuplicateVisitorAssetsQuery = {
  listDuplicateVisitorAssets?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListEmployeesQueryVariables = {
  input: ListEmployeesInput,
};

export type ListEmployeesQuery = {
  listEmployees?:  Array< {
    __typename: "EmployeeDetails",
    status: string,
    email?: string | null,
    firstName: string,
    id: string,
    idHash: string,
    jobLevel?: string | null,
    lastName: string,
    locationName?: string | null,
    region?: string | null,
    siteCode: string,
    title: string,
    username: string,
  } | null > | null,
};

export type ListLookupTypesQueryVariables = {
};

export type ListLookupTypesQuery = {
  listLookupTypes?:  Array< {
    __typename: "LookupType",
    id: string,
    name: string,
    description: string,
    enabled: boolean,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type ListLookupTypeValuesForTypeNameQueryVariables = {
  input?: listLookupTypeValuesForTypeNameInput | null,
};

export type ListLookupTypeValuesForTypeNameQuery = {
  listLookupTypeValuesForTypeName?:  Array< {
    __typename: "LookupTypeValue",
    id: string,
    lookup_type_id: string,
    value: string,
    description: string,
    enabled: boolean,
    start_date?: string | null,
    end_date?: string | null,
    created: string,
    updated: string,
    created_by: string,
    updated_by: string,
  } | null > | null,
};

export type ListSitesQueryVariables = {
  input?: ListSitesInput | null,
};

export type ListSitesQuery = {
  listSites?:  Array< {
    __typename: "Site",
    SiteCode: string,
  } | null > | null,
};

export type ListSupervisorReportsQueryVariables = {
  input: ListSupervisorReportsInput,
};

export type ListSupervisorReportsQuery = {
  listSupervisorReports?:  Array< {
    __typename: "Report",
    FinalPSID: string,
    FinalSupID?: string | null,
    SupervisorHeightAbove: number,
    inserted_datetime_utc: string,
    username?: string | null,
  } | null > | null,
};

export type ListVisitorAccessLevelApprovalsForVisitorAccessLevelQueryVariables = {
  visitor_access_level_id: string,
};

export type ListVisitorAccessLevelApprovalsForVisitorAccessLevelQuery = {
  listVisitorAccessLevelApprovalsForVisitorAccessLevel?:  Array< {
    __typename: "VisitorAccessLevelApproval",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    id: string,
    notes?: string | null,
    status: string,
    status_code_id: string,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
  } | null > | null,
};

export type ListVisitorAccessLevelApprovalsForRequestQueryVariables = {
  request_id: string,
};

export type ListVisitorAccessLevelApprovalsForRequestQuery = {
  listVisitorAccessLevelApprovalsForRequest?:  Array< {
    __typename: "VisitorAccessLevelApprovalForRequest",
    access_level_id?: string | null,
    access_level_name?: string | null,
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    email?: string | null,
    id: string,
    last_name?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    phone_number?: string | null,
    request_end_date?: string | null,
    request_id: string,
    request_reason?: string | null,
    request_site_id?: string | null,
    request_start_date?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status: string,
    status_code_id: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_access_level_id: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsForRequestorQueryVariables = {
  requestor_id: string,
  requestor_source_system_id: string,
  person_id: string,
  person_source_system_id: string,
  status: string,
};

export type ListVisitorAccessLevelsForRequestorQuery = {
  listVisitorAccessLevelsForRequestor?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsQueryVariables = {
  status?: string | null,
  limit: number,
  offset: number,
  updated_max?: string | null,
  updated_min?: string | null,
};

export type ListVisitorAccessLevelsQuery = {
  listVisitorAccessLevels?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsForApproverQueryVariables = {
  approver_id: string,
  approver_source_system_id: string,
  limit: number,
  offset: number,
  status?: string | null,
  updated_max?: string | null,
  updated_min?: string | null,
};

export type ListVisitorAccessLevelsForApproverQuery = {
  listVisitorAccessLevelsForApprover?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsForVisitorQueryVariables = {
  visitor_id: string,
};

export type ListVisitorAccessLevelsForVisitorQuery = {
  listVisitorAccessLevelsForVisitor?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsForRequestQueryVariables = {
  request_id: string,
};

export type ListVisitorAccessLevelsForRequestQuery = {
  listVisitorAccessLevelsForRequest?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorAccessLevelsForRequestAndVisitorQueryVariables = {
  request_id: string,
  visitor_id: string,
};

export type ListVisitorAccessLevelsForRequestAndVisitorQuery = {
  listVisitorAccessLevelsForRequestAndVisitor?:  Array< {
    __typename: "VisitorAccessLevel",
    access_level_id: string,
    access_level_name: string,
    access_level_source_system_id: string,
    company?: string | null,
    created: string,
    created_by: string,
    dates_updated?: boolean | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    last_reminder_date?: string | null,
    person_id: string,
    person_source_system_id?: string | null,
    permanent_flag: boolean,
    reason: string,
    request_id: string,
    requestor_id: string,
    requestor_source_system_id: string,
    requestuuid?: string | null,
    site_id?: string | null,
    start_date?: string | null,
    status?: string | null,
    status_code_id?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    visitor_type: string,
  } | null > | null,
};

export type ListVisitorActionsQueryVariables = {
  visitor_id: string,
};

export type ListVisitorActionsQuery = {
  listVisitorActions?:  Array< {
    __typename: "VisitorAction",
    id: string,
    visitor_id: string,
    action_type: string,
    action_type_id: string,
    additional_info: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListVisitorRequestsForEscortQueryVariables = {
  escort_id: string,
  escort_source_system_id: string,
  status: string,
};

export type ListVisitorRequestsForEscortQuery = {
  listVisitorRequestsForEscort?:  Array< {
    __typename: "VisitorRequest",
    approvals_needed: string,
    badge_id?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    end_date?: string | null,
    escort1?: string | null,
    escort2?: string | null,
    escort3?: string | null,
    escort4?: string | null,
    escort5?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    reason?: string | null,
    request_id?: string | null,
    requestor_id?: string | null,
    requestor_source_system_id?: string | null,
    site_id: string,
    start_date?: string | null,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    vendor_day_pass_badge_num?: string | null,
    visitor_pass_assignment_id?: string | null,
    visitor_type: string,
    visitor_type_id: string,
  } | null > | null,
};

export type ListVisitorRequestsForRequestorQueryVariables = {
  requestor_id: string,
  requestor_source_system_id: string,
  status: string,
};

export type ListVisitorRequestsForRequestorQuery = {
  listVisitorRequestsForRequestor?:  Array< {
    __typename: "VisitorRequest",
    approvals_needed: string,
    badge_id?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    end_date?: string | null,
    escort1?: string | null,
    escort2?: string | null,
    escort3?: string | null,
    escort4?: string | null,
    escort5?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    reason?: string | null,
    request_id?: string | null,
    requestor_id?: string | null,
    requestor_source_system_id?: string | null,
    site_id: string,
    start_date?: string | null,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    vendor_day_pass_badge_num?: string | null,
    visitor_pass_assignment_id?: string | null,
    visitor_type: string,
    visitor_type_id: string,
  } | null > | null,
};

export type ListVisitorAssetApprovalsForVisitorAssetQueryVariables = {
  visitor_asset_id: string,
};

export type ListVisitorAssetApprovalsForVisitorAssetQuery = {
  listVisitorAssetApprovalsForVisitorAsset?:  Array< {
    __typename: "VisitorAssetApprovalForVisitorAsset",
    approver_id: string,
    approver_email_address?: string | null,
    approver_source_system_id: string,
    asset_type_id: string,
    asset_type: string,
    company?: string | null,
    created: string,
    created_by: string,
    denial_reason_id?: string | null,
    denial_reason?: string | null,
    description: string,
    dob_month?: string | null,
    dob_day?: string | null,
    email?: string | null,
    end_date?: string | null,
    first_name?: string | null,
    id: string,
    last_name?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id: string,
    phone_number?: string | null,
    serial_num: string,
    site_id?: string | null,
    site_source_system_id?: string | null,
    start_date?: string | null,
    status_code_id: string,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_asset_id: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsQueryVariables = {
  visitor_id?: string | null,
  site_source_system_id?: string | null,
  site_id?: string | null,
};

export type ListVisitorAssetsQuery = {
  listVisitorAssets?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForApproverQueryVariables = {
  approver_id: string,
  approver_source_system_id: string,
  status?: string | null,
  updated_max?: string | null,
  updated_min?: string | null,
};

export type ListVisitorAssetsForApproverQuery = {
  listVisitorAssetsForApprover?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForApproverV2QueryVariables = {
  approver_id: string,
  approver_source_system_id: string,
  status?: string | null,
  updated_max?: string | null,
  updated_min?: string | null,
  limit: number,
  offset: number,
};

export type ListVisitorAssetsForApproverV2Query = {
  listVisitorAssetsForApproverV2?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsByPSIDQueryVariables = {
  psid: string,
};

export type ListVisitorAssetsByPSIDQuery = {
  listVisitorAssetsByPSID?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForSiteAndNameOrEmailQueryVariables = {
  site_id: string,
  visitor_type: string,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
};

export type ListVisitorAssetsForSiteAndNameOrEmailQuery = {
  listVisitorAssetsForSiteAndNameOrEmail?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForSiteAndPersonQueryVariables = {
  site_id: string,
  person_id: string,
};

export type ListVisitorAssetsForSiteAndPersonQuery = {
  listVisitorAssetsForSiteAndPerson?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForSiteAndStickerQueryVariables = {
  site_id: string,
  sticker_num: string,
};

export type ListVisitorAssetsForSiteAndStickerQuery = {
  listVisitorAssetsForSiteAndSticker?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsForStatusQueryVariables = {
  status?: string | null,
  limit: number,
  offset: number,
};

export type ListVisitorAssetsForStatusQuery = {
  listVisitorAssetsForStatus?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorAssetsUnapprovedWithNoApprovalsQueryVariables = {
};

export type ListVisitorAssetsUnapprovedWithNoApprovalsQuery = {
  listVisitorAssetsUnapprovedWithNoApprovals?:  Array< {
    __typename: "VisitorAsset",
    approval_status?: string | null,
    approval_status_id: string,
    asset_type_id: string,
    asset_type?: string | null,
    created: string,
    created_by: string,
    denial_reason?: string | null,
    denial_reason_id?: string | null,
    description: string,
    end_date?: string | null,
    id: string,
    make?: string | null,
    model?: string | null,
    notes?: string | null,
    permanent_flag: boolean,
    person_id?: string | null,
    serial_num: string,
    site_id: string,
    site_source_system_id: string,
    start_date?: string | null,
    sticker_num?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
  } | null > | null,
};

export type ListVisitorRequestsForSiteQueryVariables = {
  limit: number,
  offset: number,
  site_id: string,
  status?: string | null,
  visit_min?: string | null,
  visit_max?: string | null,
};

export type ListVisitorRequestsForSiteQuery = {
  listVisitorRequestsForSite?:  Array< {
    __typename: "VisitorRequest",
    approvals_needed: string,
    badge_id?: string | null,
    company?: string | null,
    created: string,
    created_by: string,
    dob_month?: number | null,
    dob_day?: number | null,
    email?: string | null,
    end_date?: string | null,
    escort1?: string | null,
    escort2?: string | null,
    escort3?: string | null,
    escort4?: string | null,
    escort5?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    person_id?: string | null,
    person_source_system_id?: string | null,
    phone_number?: string | null,
    reason?: string | null,
    request_id?: string | null,
    requestor_id?: string | null,
    requestor_source_system_id?: string | null,
    site_id: string,
    start_date?: string | null,
    status: string,
    title?: string | null,
    updated: string,
    updated_by: string,
    visitor_id: string,
    vendor_day_pass_badge_num?: string | null,
    visitor_pass_assignment_id?: string | null,
    visitor_type: string,
    visitor_type_id: string,
  } | null > | null,
};

export type SNSPublishAccessLevelRequestApprovedQueryVariables = {
  visitorAccessLevelId?: string | null,
};

export type SNSPublishAccessLevelRequestApprovedQuery = {
  SNSPublishAccessLevelRequestApproved?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAccessLevelRequestCreatedQueryVariables = {
  requestId?: string | null,
};

export type SNSPublishAccessLevelRequestCreatedQuery = {
  SNSPublishAccessLevelRequestCreated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAccessLevelRequestDeclinedQueryVariables = {
  visitorAccessLevelId?: string | null,
};

export type SNSPublishAccessLevelRequestDeclinedQuery = {
  SNSPublishAccessLevelRequestDeclined?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAccessLevelRequestSendReminderQueryVariables = {
  requestId?: string | null,
};

export type SNSPublishAccessLevelRequestSendReminderQuery = {
  SNSPublishAccessLevelRequestSendReminder?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAssetRequestApprovedQueryVariables = {
  visitorAssetId?: string | null,
};

export type SNSPublishAssetRequestApprovedQuery = {
  SNSPublishAssetRequestApproved?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAssetRequestCreatedQueryVariables = {
  visitorAssetId?: string | null,
};

export type SNSPublishAssetRequestCreatedQuery = {
  SNSPublishAssetRequestCreated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishAssetRequestDeclinedQueryVariables = {
  visitorAssetId?: string | null,
};

export type SNSPublishAssetRequestDeclinedQuery = {
  SNSPublishAssetRequestDeclined?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishUserAuthenticatedQueryVariables = {
  from?: string | null,
  message?: string | null,
};

export type SNSPublishUserAuthenticatedQuery = {
  SNSPublishUserAuthenticated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishUserCreatedQueryVariables = {
  from?: string | null,
  message?: string | null,
};

export type SNSPublishUserCreatedQuery = {
  SNSPublishUserCreated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishViewershipPrivilegeGrantedQueryVariables = {
  delegationInput?: DelegationPrivilegeInput | null,
};

export type SNSPublishViewershipPrivilegeGrantedQuery = {
  SNSPublishViewershipPrivilegeGranted?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishViewershipPrivilegeRemovedQueryVariables = {
  delegationInput?: DelegationPrivilegeInput | null,
};

export type SNSPublishViewershipPrivilegeRemovedQuery = {
  SNSPublishViewershipPrivilegeRemoved?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitCancelledQueryVariables = {
  id?: string | null,
};

export type SNSPublishVisitCancelledQuery = {
  SNSPublishVisitCancelled?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitCreatedQueryVariables = {
  id?: string | null,
};

export type SNSPublishVisitCreatedQuery = {
  SNSPublishVisitCreated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitUpdatedQueryVariables = {
  id?: string | null,
};

export type SNSPublishVisitUpdatedQuery = {
  SNSPublishVisitUpdated?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitorDepartedQueryVariables = {
  visitor: VisitorDepartedInput,
};

export type SNSPublishVisitorDepartedQuery = {
  SNSPublishVisitorDeparted?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitorReceivedQueryVariables = {
  visitor: VisitorReceivedInput,
};

export type SNSPublishVisitorReceivedQuery = {
  SNSPublishVisitorReceived?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitorPassAssignedQueryVariables = {
  visitorId?: string | null,
};

export type SNSPublishVisitorPassAssignedQuery = {
  SNSPublishVisitorPassAssigned?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishVisitorPassUnassignedQueryVariables = {
  visitorId?: string | null,
};

export type SNSPublishVisitorPassUnassignedQuery = {
  SNSPublishVisitorPassUnassigned?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};
