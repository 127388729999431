/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAccessLevelsForPSID = /* GraphQL */ `query GetAccessLevelsForPSID($input: GetAccessLevelsForPSIDInput!) {
  getAccessLevelsForPSID(input: $input) {
    ACCESSLEVEL_ACTIVATE
    ACCESSLEVEL_DEACTIVATE
    ACCESSLEVEL_NAME
    ACCLVLID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccessLevelsForPSIDQueryVariables,
  APITypes.GetAccessLevelsForPSIDQuery
>;
export const getAccessRequestDetails = /* GraphQL */ `query GetAccessRequestDetails($request_id: ID!, $limit: Int!, $offset: Int!) {
  getAccessRequestDetails(
    request_id: $request_id
    limit: $limit
    offset: $offset
  ) {
    request_created
    request_created_by
    request_end_date
    request_escort_created
    request_escort_created_by
    request_escort_id
    request_escort_person_id
    request_escort_updated
    request_escort_updated_by
    request_id
    request_reason
    request_site_id
    request_start_date
    request_status
    request_updated
    request_updated_by
    requestor_id
    track_by
    visitor_access_level_approval_id
    visitor_access_level_approval_person_id
    visitor_access_level_approval_created
    visitor_access_level_approval_created_by
    visitor_access_level_approval_status
    visitor_access_level_approval_updated
    visitor_access_level_approval_updated_by
    visitor_access_level_created
    visitor_access_level_created_by
    visitor_access_level_denial_reason
    visitor_access_level_end_date
    visitor_access_level_id
    visitor_access_level_name
    visitor_access_level_start_date
    visitor_access_level_status
    visitor_access_level_updated
    visitor_access_level_updated_by
    visitor_company
    visitor_created
    visitor_created_by
    visitor_email
    visitor_first_name
    visitor_id
    visitor_last_name
    visitor_person_id
    visitor_phone_number
    visitor_status
    visitor_type
    visitor_updated
    visitor_updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccessRequestDetailsQueryVariables,
  APITypes.GetAccessRequestDetailsQuery
>;
export const getEmployeeDetailsForLogin = /* GraphQL */ `query GetEmployeeDetailsForLogin($input: GetEmployeeDetailsForLoginInput!) {
  getEmployeeDetailsForLogin(input: $input) {
    status
    email
    firstName
    id
    idHash
    jobLevel
    lastName
    locationName
    region
    siteCode
    title
    username
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeDetailsForLoginQueryVariables,
  APITypes.GetEmployeeDetailsForLoginQuery
>;
export const getEmployeeActiveBadges = /* GraphQL */ `query GetEmployeeActiveBadges($input: GetEmployeeActiveBadgesInput!) {
  getEmployeeActiveBadges(input: $input) {
    badgeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeActiveBadgesQueryVariables,
  APITypes.GetEmployeeActiveBadgesQuery
>;
export const getEmpWelcomeLink = /* GraphQL */ `query GetEmpWelcomeLink($input: GetEmpWelcomeLinkInput!) {
  getEmpWelcomeLink(input: $input) {
    empid
    welcome_guid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmpWelcomeLinkQueryVariables,
  APITypes.GetEmpWelcomeLinkQuery
>;
export const getEmpWelcomeLinkForWelcomeId = /* GraphQL */ `query GetEmpWelcomeLinkForWelcomeId(
  $input: GetEmpWelcomeLinkForWelcomeIdInput!
) {
  getEmpWelcomeLinkForWelcomeId(input: $input) {
    empid
    welcome_guid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmpWelcomeLinkForWelcomeIdQueryVariables,
  APITypes.GetEmpWelcomeLinkForWelcomeIdQuery
>;
export const getApproverGroup = /* GraphQL */ `query GetApproverGroup($id: ID!) {
  getApproverGroup(id: $id) {
    id
    access_level
    approver_group
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetApproverGroupQueryVariables,
  APITypes.GetApproverGroupQuery
>;
export const getBadgeAssignment = /* GraphQL */ `query GetBadgeAssignment($input: GetBadgeAssignmentInput) {
  getBadgeAssignment(input: $input) {
    activate
    badge_id
    badge_status_name
    badge_type_name
    deactivate
    email
    empid
    employee_id
    firstname
    lastname
    status
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBadgeAssignmentQueryVariables,
  APITypes.GetBadgeAssignmentQuery
>;
export const getLookupType = /* GraphQL */ `query GetLookupType($id: ID!) {
  getLookupType(id: $id) {
    id
    name
    description
    enabled
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypeQueryVariables,
  APITypes.GetLookupTypeQuery
>;
export const getLookupTypes = /* GraphQL */ `query GetLookupTypes {
  getLookupTypes {
    id
    name
    description
    enabled
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypesQueryVariables,
  APITypes.GetLookupTypesQuery
>;
export const getLookupTypeValue = /* GraphQL */ `query GetLookupTypeValue($id: ID!) {
  getLookupTypeValue(id: $id) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypeValueQueryVariables,
  APITypes.GetLookupTypeValueQuery
>;
export const getLookupTypeValues = /* GraphQL */ `query GetLookupTypeValues($lookup_type_id: ID!) {
  getLookupTypeValues(lookup_type_id: $lookup_type_id) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypeValuesQueryVariables,
  APITypes.GetLookupTypeValuesQuery
>;
export const getLookupTypeValueForTypeNameAndValue = /* GraphQL */ `query GetLookupTypeValueForTypeNameAndValue(
  $lookup_type_name: String!
  $value: String!
) {
  getLookupTypeValueForTypeNameAndValue(
    lookup_type_name: $lookup_type_name
    value: $value
  ) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypeValueForTypeNameAndValueQueryVariables,
  APITypes.GetLookupTypeValueForTypeNameAndValueQuery
>;
export const getLookupTypeValuesForTypeName = /* GraphQL */ `query GetLookupTypeValuesForTypeName($lookup_type_name: String!) {
  getLookupTypeValuesForTypeName(lookup_type_name: $lookup_type_name) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookupTypeValuesForTypeNameQueryVariables,
  APITypes.GetLookupTypeValuesForTypeNameQuery
>;
export const getUUID = /* GraphQL */ `query GetUUID {
  getUUID {
    uuid
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUUIDQueryVariables, APITypes.GetUUIDQuery>;
export const getUserActions = /* GraphQL */ `query GetUserActions($username: String!) {
  getUserActions(username: $username) {
    actionId
    actionName
    parameters
    timestamp
    ttl
    username
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserActionsQueryVariables,
  APITypes.GetUserActionsQuery
>;
export const getUserPrefs = /* GraphQL */ `query GetUserPrefs($username: String!) {
  getUserPrefs(username: $username) {
    username
    global
    selfService
    management
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPrefsQueryVariables,
  APITypes.GetUserPrefsQuery
>;
export const getRequest = /* GraphQL */ `query GetRequest($id: ID!) {
  getRequest(id: $id) {
    created
    created_by
    end_date
    id
    last_reminder_date
    reason
    requestor_id
    requestor_source_system_id
    site_id
    site_source_system
    site_source_system_id
    start_date
    status
    status_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestQueryVariables,
  APITypes.GetRequestQuery
>;
export const getVisitorAccessLevel = /* GraphQL */ `query GetVisitorAccessLevel($id: ID!) {
  getVisitorAccessLevel(id: $id) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorAccessLevelQueryVariables,
  APITypes.GetVisitorAccessLevelQuery
>;
export const getVisitorAccessLevelForRequestUUID = /* GraphQL */ `query GetVisitorAccessLevelForRequestUUID($requestuuid: ID!) {
  getVisitorAccessLevelForRequestUUID(requestuuid: $requestuuid) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorAccessLevelForRequestUUIDQueryVariables,
  APITypes.GetVisitorAccessLevelForRequestUUIDQuery
>;
export const getVisitorAccessLevelApproval = /* GraphQL */ `query GetVisitorAccessLevelApproval($id: ID!) {
  getVisitorAccessLevelApproval(id: $id) {
    approver_id
    approver_email_address
    approver_source_system_id
    created
    created_by
    denial_reason_id
    denial_reason
    id
    notes
    status
    status_code_id
    updated
    updated_by
    visitor_access_level_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorAccessLevelApprovalQueryVariables,
  APITypes.GetVisitorAccessLevelApprovalQuery
>;
export const getRequestsForSite = /* GraphQL */ `query GetRequestsForSite($input: GetRequestsForSiteInput) {
  getRequestsForSite(input: $input) {
    id
    ctid
    site_id
    site_source_system_id
    reason
    requestor_id
    requestor_source_system_id
    start_date
    end_date
    status_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestsForSiteQueryVariables,
  APITypes.GetRequestsForSiteQuery
>;
export const getRequestEscort = /* GraphQL */ `query GetRequestEscort($id: ID!) {
  getRequestEscort(id: $id) {
    id
    request_id
    escort_id
    escort_source_system_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestEscortQueryVariables,
  APITypes.GetRequestEscortQuery
>;
export const getVisitor = /* GraphQL */ `query GetVisitor($id: ID!) {
  getVisitor(id: $id) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorQueryVariables,
  APITypes.GetVisitorQuery
>;
export const getVisitorRequestForNameAndVendorDayPassBadgeNum = /* GraphQL */ `query GetVisitorRequestForNameAndVendorDayPassBadgeNum(
  $first_name: String!
  $last_name: String!
  $vendor_day_pass_badge_num: String!
) {
  getVisitorRequestForNameAndVendorDayPassBadgeNum(
    first_name: $first_name
    last_name: $last_name
    vendor_day_pass_badge_num: $vendor_day_pass_badge_num
  ) {
    approvals_needed
    badge_id
    company
    created
    created_by
    dob_month
    dob_day
    email
    end_date
    escort1
    escort2
    escort3
    escort4
    escort5
    first_name
    last_name
    person_id
    person_source_system_id
    phone_number
    reason
    request_id
    requestor_id
    requestor_source_system_id
    site_id
    start_date
    status
    title
    updated
    updated_by
    visitor_id
    vendor_day_pass_badge_num
    visitor_pass_assignment_id
    visitor_type
    visitor_type_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorRequestForNameAndVendorDayPassBadgeNumQueryVariables,
  APITypes.GetVisitorRequestForNameAndVendorDayPassBadgeNumQuery
>;
export const getVisitorRequestForVendorDayPassBadgeNum = /* GraphQL */ `query GetVisitorRequestForVendorDayPassBadgeNum(
  $vendor_day_pass_badge_num: String!
  $visitor_status: String!
) {
  getVisitorRequestForVendorDayPassBadgeNum(
    vendor_day_pass_badge_num: $vendor_day_pass_badge_num
    visitor_status: $visitor_status
  ) {
    approvals_needed
    badge_id
    company
    created
    created_by
    dob_month
    dob_day
    email
    end_date
    escort1
    escort2
    escort3
    escort4
    escort5
    first_name
    last_name
    person_id
    person_source_system_id
    phone_number
    reason
    request_id
    requestor_id
    requestor_source_system_id
    site_id
    start_date
    status
    title
    updated
    updated_by
    visitor_id
    vendor_day_pass_badge_num
    visitor_pass_assignment_id
    visitor_type
    visitor_type_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorRequestForVendorDayPassBadgeNumQueryVariables,
  APITypes.GetVisitorRequestForVendorDayPassBadgeNumQuery
>;
export const getVisitorByPersonId = /* GraphQL */ `query GetVisitorByPersonId(
  $person_id: String!
  $person_source_system_id: String
) {
  getVisitorByPersonId(
    person_id: $person_id
    person_source_system_id: $person_source_system_id
  ) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorByPersonIdQueryVariables,
  APITypes.GetVisitorByPersonIdQuery
>;
export const getVisitorAsset = /* GraphQL */ `query GetVisitorAsset($id: ID!) {
  getVisitorAsset(id: $id) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorAssetQueryVariables,
  APITypes.GetVisitorAssetQuery
>;
export const getVisitorAssetsForVisitor = /* GraphQL */ `query GetVisitorAssetsForVisitor($visitor_id: ID!) {
  getVisitorAssetsForVisitor(visitor_id: $visitor_id) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorAssetsForVisitorQueryVariables,
  APITypes.GetVisitorAssetsForVisitorQuery
>;
export const getVisitorsForRequest = /* GraphQL */ `query GetVisitorsForRequest($request_id: ID!) {
  getVisitorsForRequest(request_id: $request_id) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorsForRequestQueryVariables,
  APITypes.GetVisitorsForRequestQuery
>;
export const getVisitorPasses = /* GraphQL */ `query GetVisitorPasses($input: GetVisitorPasses!) {
  getVisitorPasses(input: $input) {
    id
    badge_id
    badge_source_system_id
    visitor_pass_assignment_id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorPassesQueryVariables,
  APITypes.GetVisitorPassesQuery
>;
export const getVisitorPassAssignment = /* GraphQL */ `query GetVisitorPassAssignment($id: ID!) {
  getVisitorPassAssignment(id: $id) {
    id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisitorPassAssignmentQueryVariables,
  APITypes.GetVisitorPassAssignmentQuery
>;
export const getRequestEscortsForRequest = /* GraphQL */ `query GetRequestEscortsForRequest($request_id: ID!) {
  getRequestEscortsForRequest(request_id: $request_id) {
    id
    request_id
    escort_id
    escort_source_system_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestEscortsForRequestQueryVariables,
  APITypes.GetRequestEscortsForRequestQuery
>;
export const listAccessLevelApproverGroups = /* GraphQL */ `query ListAccessLevelApproverGroups($accessLevelName: String!) {
  listAccessLevelApproverGroups(accessLevelName: $accessLevelName) {
    approver_group
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessLevelApproverGroupsQueryVariables,
  APITypes.ListAccessLevelApproverGroupsQuery
>;
export const listAccessLevelsForSite = /* GraphQL */ `query ListAccessLevelsForSite($input: ListAccessLevelsForSiteInput) {
  listAccessLevelsForSite(input: $input) {
    AccessLevelID
    AccessLevelName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessLevelsForSiteQueryVariables,
  APITypes.ListAccessLevelsForSiteQuery
>;
export const listApproverGroupMembers = /* GraphQL */ `query ListApproverGroupMembers($input: AWSJSON!) {
  listApproverGroupMembers(input: $input)
}
` as GeneratedQuery<
  APITypes.ListApproverGroupMembersQueryVariables,
  APITypes.ListApproverGroupMembersQuery
>;
export const listApproverGroups = /* GraphQL */ `query ListApproverGroups($input: listApproverGroupsInput) {
  listApproverGroups(input: $input) {
    id
    access_level
    approver_group
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListApproverGroupsQueryVariables,
  APITypes.ListApproverGroupsQuery
>;
export const listDelegationsByDelegateeID = /* GraphQL */ `query ListDelegationsByDelegateeID($delegatee_id: ID) {
  listDelegationsByDelegateeID(delegatee_id: $delegatee_id) {
    created
    created_by
    delegator_id
    delegator_username
    delegatee_id
    delegatee_username
    delegation_type_id
    delegation_type
    end_date
    justification
    id
    permanent_flag
    start_date
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDelegationsByDelegateeIDQueryVariables,
  APITypes.ListDelegationsByDelegateeIDQuery
>;
export const listDelegationsByDelegatorID = /* GraphQL */ `query ListDelegationsByDelegatorID($delegator_id: ID) {
  listDelegationsByDelegatorID(delegator_id: $delegator_id) {
    created
    created_by
    delegator_id
    delegator_username
    delegatee_id
    delegatee_username
    delegation_type_id
    delegation_type
    end_date
    justification
    id
    permanent_flag
    start_date
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDelegationsByDelegatorIDQueryVariables,
  APITypes.ListDelegationsByDelegatorIDQuery
>;
export const listDuplicateVisitorAssets = /* GraphQL */ `query ListDuplicateVisitorAssets(
  $serial_num: String!
  $type: String
  $username: String
) {
  listDuplicateVisitorAssets(
    serial_num: $serial_num
    type: $type
    username: $username
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDuplicateVisitorAssetsQueryVariables,
  APITypes.ListDuplicateVisitorAssetsQuery
>;
export const listEmployees = /* GraphQL */ `query ListEmployees($input: ListEmployeesInput!) {
  listEmployees(input: $input) {
    status
    email
    firstName
    id
    idHash
    jobLevel
    lastName
    locationName
    region
    siteCode
    title
    username
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeesQueryVariables,
  APITypes.ListEmployeesQuery
>;
export const listLookupTypes = /* GraphQL */ `query ListLookupTypes {
  listLookupTypes {
    id
    name
    description
    enabled
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLookupTypesQueryVariables,
  APITypes.ListLookupTypesQuery
>;
export const listLookupTypeValuesForTypeName = /* GraphQL */ `query ListLookupTypeValuesForTypeName(
  $input: listLookupTypeValuesForTypeNameInput
) {
  listLookupTypeValuesForTypeName(input: $input) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLookupTypeValuesForTypeNameQueryVariables,
  APITypes.ListLookupTypeValuesForTypeNameQuery
>;
export const listSites = /* GraphQL */ `query ListSites($input: ListSitesInput) {
  listSites(input: $input) {
    SiteCode
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const listSupervisorReports = /* GraphQL */ `query ListSupervisorReports($input: ListSupervisorReportsInput!) {
  listSupervisorReports(input: $input) {
    FinalPSID
    FinalSupID
    SupervisorHeightAbove
    inserted_datetime_utc
    username
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupervisorReportsQueryVariables,
  APITypes.ListSupervisorReportsQuery
>;
export const listVisitorAccessLevelApprovalsForVisitorAccessLevel = /* GraphQL */ `query ListVisitorAccessLevelApprovalsForVisitorAccessLevel(
  $visitor_access_level_id: ID!
) {
  listVisitorAccessLevelApprovalsForVisitorAccessLevel(
    visitor_access_level_id: $visitor_access_level_id
  ) {
    approver_id
    approver_email_address
    approver_source_system_id
    created
    created_by
    denial_reason_id
    denial_reason
    id
    notes
    status
    status_code_id
    updated
    updated_by
    visitor_access_level_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelApprovalsForVisitorAccessLevelQueryVariables,
  APITypes.ListVisitorAccessLevelApprovalsForVisitorAccessLevelQuery
>;
export const listVisitorAccessLevelApprovalsForRequest = /* GraphQL */ `query ListVisitorAccessLevelApprovalsForRequest($request_id: ID!) {
  listVisitorAccessLevelApprovalsForRequest(request_id: $request_id) {
    access_level_id
    access_level_name
    approver_id
    approver_email_address
    approver_source_system_id
    company
    created
    created_by
    denial_reason_id
    denial_reason
    end_date
    first_name
    email
    id
    last_name
    notes
    permanent_flag
    person_id
    phone_number
    request_end_date
    request_id
    request_reason
    request_site_id
    request_start_date
    site_id
    start_date
    status
    status_code_id
    title
    updated
    updated_by
    visitor_access_level_id
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelApprovalsForRequestQueryVariables,
  APITypes.ListVisitorAccessLevelApprovalsForRequestQuery
>;
export const listVisitorAccessLevelsForRequestor = /* GraphQL */ `query ListVisitorAccessLevelsForRequestor(
  $requestor_id: String!
  $requestor_source_system_id: ID!
  $person_id: String!
  $person_source_system_id: ID!
  $status: String!
) {
  listVisitorAccessLevelsForRequestor(
    requestor_id: $requestor_id
    requestor_source_system_id: $requestor_source_system_id
    person_id: $person_id
    person_source_system_id: $person_source_system_id
    status: $status
  ) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsForRequestorQueryVariables,
  APITypes.ListVisitorAccessLevelsForRequestorQuery
>;
export const listVisitorAccessLevels = /* GraphQL */ `query ListVisitorAccessLevels(
  $status: String
  $limit: Int!
  $offset: Int!
  $updated_max: String
  $updated_min: String
) {
  listVisitorAccessLevels(
    status: $status
    limit: $limit
    offset: $offset
    updated_max: $updated_max
    updated_min: $updated_min
  ) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsQueryVariables,
  APITypes.ListVisitorAccessLevelsQuery
>;
export const listVisitorAccessLevelsForApprover = /* GraphQL */ `query ListVisitorAccessLevelsForApprover(
  $approver_id: String!
  $approver_source_system_id: ID!
  $limit: Int!
  $offset: Int!
  $status: String
  $updated_max: String
  $updated_min: String
) {
  listVisitorAccessLevelsForApprover(
    approver_id: $approver_id
    approver_source_system_id: $approver_source_system_id
    limit: $limit
    offset: $offset
    status: $status
    updated_max: $updated_max
    updated_min: $updated_min
  ) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsForApproverQueryVariables,
  APITypes.ListVisitorAccessLevelsForApproverQuery
>;
export const listVisitorAccessLevelsForVisitor = /* GraphQL */ `query ListVisitorAccessLevelsForVisitor($visitor_id: String!) {
  listVisitorAccessLevelsForVisitor(visitor_id: $visitor_id) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsForVisitorQueryVariables,
  APITypes.ListVisitorAccessLevelsForVisitorQuery
>;
export const listVisitorAccessLevelsForRequest = /* GraphQL */ `query ListVisitorAccessLevelsForRequest($request_id: ID!) {
  listVisitorAccessLevelsForRequest(request_id: $request_id) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsForRequestQueryVariables,
  APITypes.ListVisitorAccessLevelsForRequestQuery
>;
export const listVisitorAccessLevelsForRequestAndVisitor = /* GraphQL */ `query ListVisitorAccessLevelsForRequestAndVisitor(
  $request_id: ID!
  $visitor_id: String!
) {
  listVisitorAccessLevelsForRequestAndVisitor(
    request_id: $request_id
    visitor_id: $visitor_id
  ) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAccessLevelsForRequestAndVisitorQueryVariables,
  APITypes.ListVisitorAccessLevelsForRequestAndVisitorQuery
>;
export const listVisitorActions = /* GraphQL */ `query ListVisitorActions($visitor_id: ID!) {
  listVisitorActions(visitor_id: $visitor_id) {
    id
    visitor_id
    action_type
    action_type_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorActionsQueryVariables,
  APITypes.ListVisitorActionsQuery
>;
export const listVisitorRequestsForEscort = /* GraphQL */ `query ListVisitorRequestsForEscort(
  $escort_id: String!
  $escort_source_system_id: ID!
  $status: String!
) {
  listVisitorRequestsForEscort(
    escort_id: $escort_id
    escort_source_system_id: $escort_source_system_id
    status: $status
  ) {
    approvals_needed
    badge_id
    company
    created
    created_by
    dob_month
    dob_day
    email
    end_date
    escort1
    escort2
    escort3
    escort4
    escort5
    first_name
    last_name
    person_id
    person_source_system_id
    phone_number
    reason
    request_id
    requestor_id
    requestor_source_system_id
    site_id
    start_date
    status
    title
    updated
    updated_by
    visitor_id
    vendor_day_pass_badge_num
    visitor_pass_assignment_id
    visitor_type
    visitor_type_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorRequestsForEscortQueryVariables,
  APITypes.ListVisitorRequestsForEscortQuery
>;
export const listVisitorRequestsForRequestor = /* GraphQL */ `query ListVisitorRequestsForRequestor(
  $requestor_id: String!
  $requestor_source_system_id: ID!
  $status: String!
) {
  listVisitorRequestsForRequestor(
    requestor_id: $requestor_id
    requestor_source_system_id: $requestor_source_system_id
    status: $status
  ) {
    approvals_needed
    badge_id
    company
    created
    created_by
    dob_month
    dob_day
    email
    end_date
    escort1
    escort2
    escort3
    escort4
    escort5
    first_name
    last_name
    person_id
    person_source_system_id
    phone_number
    reason
    request_id
    requestor_id
    requestor_source_system_id
    site_id
    start_date
    status
    title
    updated
    updated_by
    visitor_id
    vendor_day_pass_badge_num
    visitor_pass_assignment_id
    visitor_type
    visitor_type_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorRequestsForRequestorQueryVariables,
  APITypes.ListVisitorRequestsForRequestorQuery
>;
export const listVisitorAssetApprovalsForVisitorAsset = /* GraphQL */ `query ListVisitorAssetApprovalsForVisitorAsset($visitor_asset_id: ID!) {
  listVisitorAssetApprovalsForVisitorAsset(
    visitor_asset_id: $visitor_asset_id
  ) {
    approver_id
    approver_email_address
    approver_source_system_id
    asset_type_id
    asset_type
    company
    created
    created_by
    denial_reason_id
    denial_reason
    description
    dob_month
    dob_day
    email
    end_date
    first_name
    id
    last_name
    notes
    permanent_flag
    person_id
    phone_number
    serial_num
    site_id
    site_source_system_id
    start_date
    status_code_id
    status
    title
    updated
    updated_by
    visitor_asset_id
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetApprovalsForVisitorAssetQueryVariables,
  APITypes.ListVisitorAssetApprovalsForVisitorAssetQuery
>;
export const listVisitorAssets = /* GraphQL */ `query ListVisitorAssets(
  $visitor_id: ID
  $site_source_system_id: String
  $site_id: String
) {
  listVisitorAssets(
    visitor_id: $visitor_id
    site_source_system_id: $site_source_system_id
    site_id: $site_id
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsQueryVariables,
  APITypes.ListVisitorAssetsQuery
>;
export const listVisitorAssetsForApprover = /* GraphQL */ `query ListVisitorAssetsForApprover(
  $approver_id: String!
  $approver_source_system_id: ID!
  $status: String
  $updated_max: String
  $updated_min: String
) {
  listVisitorAssetsForApprover(
    approver_id: $approver_id
    approver_source_system_id: $approver_source_system_id
    status: $status
    updated_max: $updated_max
    updated_min: $updated_min
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForApproverQueryVariables,
  APITypes.ListVisitorAssetsForApproverQuery
>;
export const listVisitorAssetsForApproverV2 = /* GraphQL */ `query ListVisitorAssetsForApproverV2(
  $approver_id: String!
  $approver_source_system_id: ID!
  $status: String
  $updated_max: String
  $updated_min: String
  $limit: Int!
  $offset: Int!
) {
  listVisitorAssetsForApproverV2(
    approver_id: $approver_id
    approver_source_system_id: $approver_source_system_id
    status: $status
    updated_max: $updated_max
    updated_min: $updated_min
    limit: $limit
    offset: $offset
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForApproverV2QueryVariables,
  APITypes.ListVisitorAssetsForApproverV2Query
>;
export const listVisitorAssetsByPSID = /* GraphQL */ `query ListVisitorAssetsByPSID($psid: ID!) {
  listVisitorAssetsByPSID(psid: $psid) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsByPSIDQueryVariables,
  APITypes.ListVisitorAssetsByPSIDQuery
>;
export const listVisitorAssetsForSiteAndNameOrEmail = /* GraphQL */ `query ListVisitorAssetsForSiteAndNameOrEmail(
  $site_id: String!
  $visitor_type: String!
  $first_name: String
  $last_name: String
  $email: String
) {
  listVisitorAssetsForSiteAndNameOrEmail(
    site_id: $site_id
    visitor_type: $visitor_type
    first_name: $first_name
    last_name: $last_name
    email: $email
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForSiteAndNameOrEmailQueryVariables,
  APITypes.ListVisitorAssetsForSiteAndNameOrEmailQuery
>;
export const listVisitorAssetsForSiteAndPerson = /* GraphQL */ `query ListVisitorAssetsForSiteAndPerson(
  $site_id: String!
  $person_id: String!
) {
  listVisitorAssetsForSiteAndPerson(site_id: $site_id, person_id: $person_id) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForSiteAndPersonQueryVariables,
  APITypes.ListVisitorAssetsForSiteAndPersonQuery
>;
export const listVisitorAssetsForSiteAndSticker = /* GraphQL */ `query ListVisitorAssetsForSiteAndSticker(
  $site_id: String!
  $sticker_num: String!
) {
  listVisitorAssetsForSiteAndSticker(
    site_id: $site_id
    sticker_num: $sticker_num
  ) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForSiteAndStickerQueryVariables,
  APITypes.ListVisitorAssetsForSiteAndStickerQuery
>;
export const listVisitorAssetsForStatus = /* GraphQL */ `query ListVisitorAssetsForStatus($status: String, $limit: Int!, $offset: Int!) {
  listVisitorAssetsForStatus(status: $status, limit: $limit, offset: $offset) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsForStatusQueryVariables,
  APITypes.ListVisitorAssetsForStatusQuery
>;
export const listVisitorAssetsUnapprovedWithNoApprovals = /* GraphQL */ `query ListVisitorAssetsUnapprovedWithNoApprovals {
  listVisitorAssetsUnapprovedWithNoApprovals {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorAssetsUnapprovedWithNoApprovalsQueryVariables,
  APITypes.ListVisitorAssetsUnapprovedWithNoApprovalsQuery
>;
export const listVisitorRequestsForSite = /* GraphQL */ `query ListVisitorRequestsForSite(
  $limit: Int!
  $offset: Int!
  $site_id: String!
  $status: String
  $visit_min: String
  $visit_max: String
) {
  listVisitorRequestsForSite(
    limit: $limit
    offset: $offset
    site_id: $site_id
    status: $status
    visit_min: $visit_min
    visit_max: $visit_max
  ) {
    approvals_needed
    badge_id
    company
    created
    created_by
    dob_month
    dob_day
    email
    end_date
    escort1
    escort2
    escort3
    escort4
    escort5
    first_name
    last_name
    person_id
    person_source_system_id
    phone_number
    reason
    request_id
    requestor_id
    requestor_source_system_id
    site_id
    start_date
    status
    title
    updated
    updated_by
    visitor_id
    vendor_day_pass_badge_num
    visitor_pass_assignment_id
    visitor_type
    visitor_type_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitorRequestsForSiteQueryVariables,
  APITypes.ListVisitorRequestsForSiteQuery
>;
export const SNSPublishAccessLevelRequestApproved = /* GraphQL */ `query SNSPublishAccessLevelRequestApproved($visitorAccessLevelId: ID) {
  SNSPublishAccessLevelRequestApproved(
    visitorAccessLevelId: $visitorAccessLevelId
  ) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAccessLevelRequestApprovedQueryVariables,
  APITypes.SNSPublishAccessLevelRequestApprovedQuery
>;
export const SNSPublishAccessLevelRequestCreated = /* GraphQL */ `query SNSPublishAccessLevelRequestCreated($requestId: ID) {
  SNSPublishAccessLevelRequestCreated(requestId: $requestId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAccessLevelRequestCreatedQueryVariables,
  APITypes.SNSPublishAccessLevelRequestCreatedQuery
>;
export const SNSPublishAccessLevelRequestDeclined = /* GraphQL */ `query SNSPublishAccessLevelRequestDeclined($visitorAccessLevelId: ID) {
  SNSPublishAccessLevelRequestDeclined(
    visitorAccessLevelId: $visitorAccessLevelId
  ) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAccessLevelRequestDeclinedQueryVariables,
  APITypes.SNSPublishAccessLevelRequestDeclinedQuery
>;
export const SNSPublishAccessLevelRequestSendReminder = /* GraphQL */ `query SNSPublishAccessLevelRequestSendReminder($requestId: ID) {
  SNSPublishAccessLevelRequestSendReminder(requestId: $requestId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAccessLevelRequestSendReminderQueryVariables,
  APITypes.SNSPublishAccessLevelRequestSendReminderQuery
>;
export const SNSPublishAssetRequestApproved = /* GraphQL */ `query SNSPublishAssetRequestApproved($visitorAssetId: String) {
  SNSPublishAssetRequestApproved(visitorAssetId: $visitorAssetId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAssetRequestApprovedQueryVariables,
  APITypes.SNSPublishAssetRequestApprovedQuery
>;
export const SNSPublishAssetRequestCreated = /* GraphQL */ `query SNSPublishAssetRequestCreated($visitorAssetId: String) {
  SNSPublishAssetRequestCreated(visitorAssetId: $visitorAssetId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAssetRequestCreatedQueryVariables,
  APITypes.SNSPublishAssetRequestCreatedQuery
>;
export const SNSPublishAssetRequestDeclined = /* GraphQL */ `query SNSPublishAssetRequestDeclined($visitorAssetId: String) {
  SNSPublishAssetRequestDeclined(visitorAssetId: $visitorAssetId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishAssetRequestDeclinedQueryVariables,
  APITypes.SNSPublishAssetRequestDeclinedQuery
>;
export const SNSPublishUserAuthenticated = /* GraphQL */ `query SNSPublishUserAuthenticated($from: String, $message: String) {
  SNSPublishUserAuthenticated(from: $from, message: $message) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishUserAuthenticatedQueryVariables,
  APITypes.SNSPublishUserAuthenticatedQuery
>;
export const SNSPublishUserCreated = /* GraphQL */ `query SNSPublishUserCreated($from: String, $message: String) {
  SNSPublishUserCreated(from: $from, message: $message) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishUserCreatedQueryVariables,
  APITypes.SNSPublishUserCreatedQuery
>;
export const SNSPublishViewershipPrivilegeGranted = /* GraphQL */ `query SNSPublishViewershipPrivilegeGranted(
  $delegationInput: DelegationPrivilegeInput
) {
  SNSPublishViewershipPrivilegeGranted(delegationInput: $delegationInput) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishViewershipPrivilegeGrantedQueryVariables,
  APITypes.SNSPublishViewershipPrivilegeGrantedQuery
>;
export const SNSPublishViewershipPrivilegeRemoved = /* GraphQL */ `query SNSPublishViewershipPrivilegeRemoved(
  $delegationInput: DelegationPrivilegeInput
) {
  SNSPublishViewershipPrivilegeRemoved(delegationInput: $delegationInput) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishViewershipPrivilegeRemovedQueryVariables,
  APITypes.SNSPublishViewershipPrivilegeRemovedQuery
>;
export const SNSPublishVisitCancelled = /* GraphQL */ `query SNSPublishVisitCancelled($id: ID) {
  SNSPublishVisitCancelled(id: $id) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitCancelledQueryVariables,
  APITypes.SNSPublishVisitCancelledQuery
>;
export const SNSPublishVisitCreated = /* GraphQL */ `query SNSPublishVisitCreated($id: ID) {
  SNSPublishVisitCreated(id: $id) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitCreatedQueryVariables,
  APITypes.SNSPublishVisitCreatedQuery
>;
export const SNSPublishVisitUpdated = /* GraphQL */ `query SNSPublishVisitUpdated($id: ID) {
  SNSPublishVisitUpdated(id: $id) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitUpdatedQueryVariables,
  APITypes.SNSPublishVisitUpdatedQuery
>;
export const SNSPublishVisitorDeparted = /* GraphQL */ `query SNSPublishVisitorDeparted($visitor: VisitorDepartedInput!) {
  SNSPublishVisitorDeparted(visitor: $visitor) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitorDepartedQueryVariables,
  APITypes.SNSPublishVisitorDepartedQuery
>;
export const SNSPublishVisitorReceived = /* GraphQL */ `query SNSPublishVisitorReceived($visitor: VisitorReceivedInput!) {
  SNSPublishVisitorReceived(visitor: $visitor) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitorReceivedQueryVariables,
  APITypes.SNSPublishVisitorReceivedQuery
>;
export const SNSPublishVisitorPassAssigned = /* GraphQL */ `query SNSPublishVisitorPassAssigned($visitorId: ID) {
  SNSPublishVisitorPassAssigned(visitorId: $visitorId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitorPassAssignedQueryVariables,
  APITypes.SNSPublishVisitorPassAssignedQuery
>;
export const SNSPublishVisitorPassUnassigned = /* GraphQL */ `query SNSPublishVisitorPassUnassigned($visitorId: ID) {
  SNSPublishVisitorPassUnassigned(visitorId: $visitorId) {
    MessageId
    SequenceNumber
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SNSPublishVisitorPassUnassignedQueryVariables,
  APITypes.SNSPublishVisitorPassUnassignedQuery
>;
